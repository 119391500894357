import React from 'react'
import Loader from 'react-loader-spinner'

const LoaderComponent = () => {
	return (
		<Loader
			type='Puff'
			color='#00BFFF'
			height={50}
			width={50}
			type='Oval'
			className='loader-alone'
		/>
	)
}
export default LoaderComponent
