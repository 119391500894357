import React from 'react'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'

const MenuItem = ({
	id,
	content = 'menu',
	path,
	subMenu,
	isActive,
	onClickMenu
}) => {
	const menuClassName = ['menuItem']
	if (subMenu) {
		menuClassName.push('subMenu')
	}

	if (isActive) {
		menuClassName.push('active')
	}

	const _onClickMenu = (e) => {
		if (onClickMenu && typeof onClickMenu === 'function') {
			onClickMenu(e, path)
		}
	}
	return (
		<Link
			className={menuClassName.join(' ')}
			to={path}
			onClick={ _onClickMenu}
		>
			<div className="menuItem__content">
				{id ? `${id}. ${content}` : content}
			</div>
		</Link>
	)
}
export default MenuItem
