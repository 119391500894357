import { routerPath } from 'constants'
export const translateName = (name) => {
    switch (name) {
        case routerPath.mainPage:
        case routerPath.adminMainPage:
            return 'Etusivu'
        case routerPath.addHours:
            return 'Lisää työtunnit'
        case routerPath.checkInfoList:
        case routerPath.checkInfoHandle:
            return 'Tarkastele omia työtunteja'
        case routerPath.adminCheckWorkHour:
            return 'Työtunnit - Muokkaa työtunteja'
        case routerPath.adminPendingWorkHour:
            return 'Työtunnit - Odottaa hyväksyntää'
        case routerPath.adminWorkhours:
        case routerPath.employeeWorkHoursDetails:
            return 'Työtunnit'
        case routerPath.adminAddWorkHour:
            return 'Työtunnit - Lisää  työtunnit'
        case routerPath.adminUserManagement:
            return 'Käyttäjien Hallinta'
        case routerPath.adminAddEmployee:
            return 'Lisää työntekijä'
        case routerPath.ownProfile:
            return 'Oma profiili'
        default: return null
    }
}
