/**
 * adminReport reducer
 *
 * @author Sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { adminReportTypes as types } from '../actions/types'
import { normalizeError } from './utils'

const initialState = {
    loading: false,
    error: null
}

export const adminReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.resetFields:
            return {
                ...initialState
            }
        case types.getReport:
            return {
                ...state,
                loading: true
            }

        case types.getReportSuccess:
        case types.getAllReportSuccess:
        case types.getSelectedReportSuccess:
            return {
                ...initialState
            }

        case types.getReportFail:
        case types.getAllReportFail:
        case types.getSelectedReportFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        default:
            return state
    }
}

export const adminReportSelectors = {
    getError: (state = initialState) => state.error,
    getEmail: (state = initialState) => state.email
}
