/**
 * Main container
 *
 * @author Sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { hot } from 'react-hot-loader/root'
import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
// Reducers
import { userSelectors } from 'reducers'
// Components
import CoreLayout from 'shared/layouts/CoreLayout'
import Loader from 'shared/components/Loader'
// Router
import AuthenticatedRouter from './routers/AuthenticatedRouter'
import UnauthenticatedRouter from './routers/UnauthenticatedRouter'
class App extends Component {
	render() {
		const { isAuthorized, isAdmin } = this.props
		return (
			<Suspense fallback={<Loader />}>
				<div className='app__content'>
					<Helmet titleTemplate='%s - KOPPO ' defaultTitle='Kuljetus KOPPO'>
						<meta name='description' content='Kuljetus KOPPO' />
					</Helmet>
					<Router>
						<CoreLayout>
							{isAuthorized ? (
								<AuthenticatedRouter isAdmin={isAdmin} />
							) : (
								<UnauthenticatedRouter />
							)}
						</CoreLayout>
					</Router>
				</div>
			</Suspense>
		)
	}
}

const mapStateToProps = state => ({
	isAuthorized: userSelectors.isAuthorized(state.user),
	isAdmin: userSelectors.getIsAdmin(state.user)
})

export default hot(connect(mapStateToProps)(withRouter(App)))
