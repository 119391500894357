/**
 * Core Layout component
 * Wrap the root component with layout UI components
 * e.g Navigation, Footer, Modal, Alert...
 *
 * @ Sravanthi Bandaru <sravanthi@vertics.co>
 *
 */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
// Actions
import { userActions } from '../../actions'

// Selectors
import { userSelectors } from '../../reducers'
import SideBar from './Sidebar'
import Header from './Header'
import koppo_logo from 'assets/logos/koppo_logo.svg'

// Hooks
import { AuthorizationContext, useAuthorization } from '../../hooks'

// constant
import { routerPath } from 'constants'

const CoreLayout = ({ children, location }) => {
	const user = useSelector(state => userSelectors.getUser(state.user))
	const loading = useSelector(state => userSelectors.getLoading(state.user))
	const isAuthenticated = useSelector(state =>
		userSelectors.getUser(state.user)
	)
	const { pathname } = location
	const mainClass = ['main__content']
	if (pathname === routerPath.login || pathname === routerPath.forgotPassword || pathname === routerPath.setPassword || pathname === routerPath.passwordFail) {
		mainClass.push("noHeight")
	}
	/* ⁄ */
	if (!isAuthenticated) {
		mainClass.push('noTop')
	}

	if (!isAuthenticated) {
		localStorage.clear()
	}
	return (
		<AuthorizationContext.Provider value={{ user, loading }}>
			<div className='core-layout' id='core-layout'>
				{isAuthenticated ? (
					<SideBar pageWrapId={'content'} outerContainerId={'core-layout'} />
				) : null}
				<div className='core-layout__content' id='content'>
					<div className='main'>
						{isAuthenticated ? (
							<div className='main__img'>
								<img src={koppo_logo} />
							</div>
						) : null}
						<div className={mainClass.join(' ')}>
							{isAuthenticated ? (
								<Header pageName={location.pathname} user={user} />
							) : null}
							{children}
						</div>
					</div>
				</div>
			</div>
		</AuthorizationContext.Provider>
	)
}

export default withRouter(CoreLayout)
