/**
 * Root reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

// Login
import { loginReducer, loginSelectors } from './login'
// User
import { userReducer, userSelectors } from './user'
// Password
import { passwordReducer, passwordSelectors } from './password'
// CheckInfo
import { checkInfoReducer, checkInfoSelectors } from './checkInfo'
// LogHours
import { logHoursReducer, logHoursSelectors } from './logHours'
// Admin LogHours
import { adminLogHoursReducer, adminLogHoursSelectors } from './adminLogHours'
// AdminUser
import { adminUserReducer, adminUserSelectors } from './adminUser'
// admin report
import { adminReportReducer, adminReportSelectors } from './adminReport'

// export root reducer
export const rootReducer = combineReducers({
	router: connectRouter(history),
	login: loginReducer,
	user: userReducer,
	password: passwordReducer,
	checkInfo: checkInfoReducer,
	logHours: logHoursReducer,
	adminLogHours: adminLogHoursReducer,
	adminUser: adminUserReducer,
	adminReport: adminReportReducer
})

// export history
export { history }

// export getters/ selectors
export { loginSelectors, userSelectors, adminReportSelectors, passwordSelectors, checkInfoSelectors, logHoursSelectors, adminLogHoursSelectors, adminUserSelectors }
