/**
 * Check info action
 *
 * @author Sravanthi Bandaru<sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { adminLogHoursTypes as types } from './types'
import Qs from 'qs'
const changeField = (name, value) => {
    return {
        type: types.changeField,
        name,
        value
    }
}
const getAwaitedLogHours = (params) => ({
    type: types.getAwaitedLogHours,
    payload: {
        request: {
            method: 'GET',
            url: `/admin/logged-hours`,
            params,
            paramsSerializer: function (params) {
                return Qs.stringify(params, {arrayFormat: 'brackets'})
              }
        }
    }
})
const deleteLogHour = (id) => ({
	type: types.deleteLogHour,
	payload: {
		request: {
			method: 'DELETE',
			url: `/admin/logged-hours/${id}`
				}
	}
})
const getTotalUsersLoggedHours = (params) => ({
    type: types.getTotalUsersLoggedHours,
    payload: {
        request: {
            method: 'GET',
            url: `/admin/logged-hours/summary/users`,
            params,
            paramsSerializer: function (params) {
                return Qs.stringify(params, {arrayFormat: 'brackets'})
              }
        }
    }
})
const editLogHours = (id, data) => ({
    type: types.editLogHours,
    payload: {
        request: {
            method: 'PATCH',
            url: `/admin/logged-hours/${id}`,
            data
        }
    }
})
const acceptLogHours = (id) => ({
    type: types.acceptLogHours,
    payload: {
        request: {
            method: 'PATCH',
            url: `/admin/logged-hours/${id}/accept`
        }
    }
})
const rejectLogHours = (id) => ({
    type: types.rejectLogHours,
    payload: {
        request: {
            method: 'PATCH',
            url: `/admin/logged-hours/${id}/reject`
        }
    }
})
const getSelectedUserLogHours = (id, params) => ({
    type: types.getSelectedUserLogHours,
    payload: {
        request: {
            method: 'GET',
            url: `/admin/logged-hours/users/${id}`,
            params,
            paramsSerializer: function (params) {
                return Qs.stringify(params, {arrayFormat: 'brackets'})
              }
        }
    }
})
const getUserLogHoursSummary = (id, params) => ({
    type: types.getUserLogHoursSummary,
    payload: {
        request: {
            method: 'GET',
            url: `/admin/logged-hours/summary/users/${id}`,
            params
        }
    }
})
const createTempUserLogHours = (id, data) => ({
    type: types.createTempUserLogHours,
    payload: {
        request: {
            method: 'POST',
            url: `/admin/logged-hours/users/${id}`,
            data
        }
    }
})
const getAllSalaryPeriods = () => ({
    type: types.getAllSalaryPeriods,
    payload: {
        request: {
            method: 'GET',
            url: `/admin/logged-hours/payment-period`
        }
    }
})


const resetFields = () => {
    return {
        type: types.resetFields
    }
}

const resetError = () => {
    return {
        type: types.resetError
    }
}
export const adminLogHoursActions = {
    changeField,
    resetError,
    resetFields,
    getTotalUsersLoggedHours,
    getAwaitedLogHours,
    acceptLogHours,
    rejectLogHours,
    getSelectedUserLogHours,
    getUserLogHoursSummary,
    editLogHours,
    createTempUserLogHours,
    getAllSalaryPeriods,
    deleteLogHour
}
