/**
 * Configure redux store
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { routerMiddleware } from 'connected-react-router'
import axios from 'axios'
import { multiClientMiddleware } from 'redux-axios-middleware'
import logger from 'redux-logger'

import { rootReducer, history } from './reducers'
import { KOPPO_BACKEND_URL, PERSIST_KEY } from 'constants'
// utils
import { apiWrapper } from './utils'
import { userActions } from 'actions'

// create clients
const clients = {
	default: {
		client: axios.create({
			baseURL: KOPPO_BACKEND_URL,
			responseType: 'json'
		})
	}
}

// create inteceptor
const options = {
	returnRejectedPromiseOnError: true,
	interceptors: {
		request: [
			({}, config) => {
				// Add authentication tokens to request header
				const token = localStorage.getItem('token')
				if (token) {
					config.headers.authorization = `Bearer ${token}`
				}
				return config
			}
		],
		response: [
			{
				success: ({}, response) => {
					return response
				},
				error: async ({}, error) => {
					const { data, config, status } = error.response
					const token = localStorage.getItem('token')
					if (error.response && error.response.status === 401 && token) {
						const { refreshToken } = store.getState().user
						const res = await store.dispatch(
							userActions.getNewJWT(refreshToken)
						)
						if (res.payload && res.payload.status === 200) {
							// New request with new token
							config.headers.authorization = `Bearer ${res.payload.data.accessToken}`
							localStorage.setItem('token', res.payload.data.accessToken)
							return new Promise((resolve, reject) => {
								axios
									.request(config)
									.then(response => {
										resolve(response)
									})
									.catch(error => {
										reject(error)
									})
							})
						}
					}
					return Promise.reject(error)
				}
			}
		]
	}
}

const middleware = [thunk, multiClientMiddleware(clients, options)]
//  Config redux-logger in development
const isProduction = process.env.NODE_ENV === 'production'
if (!isProduction) {
	middleware.push(logger)
}
// Config redux-persist
const persistConfig = {
	key: PERSIST_KEY,
	storage,
	blacklist: ['router']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
const tools = [applyMiddleware(...middleware, routerMiddleware(history))]
if (!isProduction && window.__REDUX_DEVTOOLS_EXTENSION__) {
	tools.push(window.__REDUX_DEVTOOLS_EXTENSION__())
}

// Create redux store
const store = createStore(persistedReducer, compose(...tools))
const persistor = persistStore(store)
export { store, persistor }
