/**
 * User reducer
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { loginTypes, userTypes } from '../actions/types'
import { normalizeError } from './utils'

const initialState = {
	user: null,
	loading: false,
	error: null,
	selectedUser: null,
	password: '',
	rePassword: ''
};

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case userTypes.changeField:
			if (action.fieldType) {
				return {
					...state,
					[action.fieldType]: {
						...state[action.fieldType],
						[action.name]: action.value
					}
				}
			}
			return {
				...state,
				[action.name]: action.value
			}
		case loginTypes.login:
		case userTypes.getUser:
			return {
				...state,
				loading: true
			}
		case userTypes.updateInfo:
			return {
				...state,
				loading: true
			}
		case userTypes.updateInfoSuccess:
			return {
				...state,
				selectedUser: action.payload.data,
				loading: false
			}
		case userTypes.updateInfoFail:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case userTypes.changePassword:
			return {
				...state,
				loading: true
			}
		case userTypes.changePasswordSuccess:
			return {
				...state,
				loading: false
			}
		case userTypes.changePasswordFail:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case loginTypes.loginSuccess:
		case userTypes.getUserSuccess:
			const { user, token, refreshToken } = action.payload.data
			return {
				...state,
				user,
				token,
				refreshToken,
				loading: false,
				isAdmin: user.role === 'admin'
			}

		case userTypes.getUserFail:
			return {
				...state,
				loading: false
			}
		case userTypes.resetFields:
			return {
				...initialState
			}
		case userTypes.getNewJWTSuccess:
			return {
				...state,
				token: action.payload.data.accessToken
			}
		default:
			return state;
	}
}

export const userSelectors = {
	getError: (state = initialState) => state.error,
	getUser: (state = initialState) => state.user,
	getLoading: (state = initialState) => state.loading,
	isAuthorized: (state = initialState) => !!state.token && !!state.refreshToken,
	getIsAdmin: (state = initialState) => state.isAdmin,
	getSelectedUser: (state = initialState) => state.selectedUser,
	getPassword: (state = initialState) => state.password,
	getRePassword: (state = initialState) => state.rePassword
}