import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
// Reucers
import { userSelectors } from 'reducers'
import { loginActions, userActions } from 'actions'
// components
import Dropdown from 'shared/components/Dropdown'
import Avatar from 'shared/components/Avatar'

// utils
import { translateName } from './utils'
// constants
import { routerPath } from 'constants'

const Header = ({ user, pageName, history, dispatch }) => {
	if (!user) {
		return null
	}
	const onAppLogout = () => {
		dispatch(userActions.logout())
		dispatch(loginActions.resetFields())
		dispatch(userActions.resetFields())
		history.push(routerPath.login)
	}
	const getDate = () => {
		const momentDate = moment().locale('FI')
		return `Tänään, ${momentDate.format('MMMM DD')}`
	}
	const _onMenuItemClick = (e, item) => {
		e.stopPropagation()
		switch (item) {
			case 'profile':
				dispatch(
					userActions.changeField("selectedUser", user)
				)
				return history.replace(routerPath.ownProfile)
			case 'logout':
				onAppLogout()
				return
			default:
				return
		}
	}
	const name = `${user.firstName[0]}${user.lastName[0]}`
	return (
		<div className='header'>
			<div className='header__pageName'>{translateName(pageName)}</div>
			<div className='header__date'>
				{user.role !== 'admin' ? getDate() : null}
			</div>
			<div className='header__user'>
				<div className='header__user-name'>
					{user.firstName} {user.lastName}
				</div>
				<Dropdown
					onMenuItemClick={_onMenuItemClick}
					options={[
						{ text: 'Oma profiili', value: 'profile' },
						{ text: 'Kirjaudu ulos', value: 'logout' }
					]}
					title={
						<Avatar
							id={user.avatar && user.avatar.id}
							imageKey={user.avatar && user.avatar.key}
						/>
					}
				/>
			</div>
		</div>
	)
}
const mapStateToProps = state => ({
	user: userSelectors.getUser(state.user)
})
export default connect(mapStateToProps)(withRouter(Header))
