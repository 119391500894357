import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import { Home, PlusCircle, Calendar, Folder, UserCheck } from 'react-feather'
// Actions
import { checkInfoActions, loginActions, userActions } from 'actions'
// Reducers
import { userSelectors } from 'reducers'
// component
import MenuItemComponent from './MenuItem'
import Button from '../components/Button'
// constants
import { routerPath } from 'constants'
// images
import companyLogo from 'assets/logos/koppo_logo.svg'

class SideBar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			menuOpen: null
		}
	}
	getMenu = () => {
		const { isAdmin } = this.props
		let data = [
			{
				component: <Home />,
				title: 'ETUSIVU',
				path: routerPath.mainPage
			},
			{
				component: <PlusCircle />,
				title: 'LISÄÄ TYÖTUNTI',
				path: routerPath.addHours
			},
			{
				component: <Calendar />,
				title: 'TARKASTELE OMIA TYÖTUNTEJA',
				path: routerPath.checkInfoList
			}
		]
		if (isAdmin) {
			data = [
				{
					component: <Home />,
					title: 'ETUSIVU',
					path: routerPath.adminMainPage
				},
				{
					component: <Folder />,
					title: 'TYÖTUNNIT',
					path: routerPath.adminWorkhours
				},
				{
					component: <UserCheck />,
					title: 'KÄYTTÄJIEN HALLINTA',
					path: routerPath.adminUserManagement
				}
			]
		}
		return data.map((item, key) => {
			return {
				content: (
					<div className='menuItem__content-text' key={key}>
						{item.component}
						<span className='label'>{item.title}</span>
					</div>
				),
				path: item.path
			}
		})
	}
	onMenuToggle = e =>
		this.setState({
			menuAnchor: e.currentTarget
		})
	onLinkSelect = (e, path) => {
		e.preventDefault()
		if (path === '/dashboard/check-information') {
			this.props.dispatch(checkInfoActions.changeField('selectedShift', null))
		}
		this.setState({ menuOpen: false })
		this.props.history.push(path)

	}
	onAppLogout = () => {
		this.props.dispatch(userActions.logout())
		this.props.dispatch(loginActions.resetFields())
		this.props.dispatch(userActions.resetFields())
		this.props.history.push(routerPath.login)
	}
	onClickAdd = () => {
		this.props.history.push(routerPath.adminAddEmployee)
	}
	renderDropdownMenu = () => {
		const { pathname } = location
		const { isAdmin } = this.props
		const menu = this.getMenu()
		const { menuOpen } = this.state
		return (
			<Menu
				isOpen={menuOpen}
				onOpen={() => this.setState({ menuOpen: true })}
				onClose={() => this.setState({ menuOpen: false })}
			>
				<img src={companyLogo} alt='koppo logo' />
				{menu.map((item, key) => {
					const linkClass = ['menu-item']
					if (pathname === item.path) {
						linkClass.push('active')
					}
					return (
						<a
							className={linkClass.join(' ')}
							key={key}
							onClick={e => this.onLinkSelect(e, item.path)}
						>
							{item.content}
						</a>
					)
				})}
				<div className='buttons'>
					{isAdmin ? (
						<Button text='LISÄÄ TYÖNTEKIJÄ' onClick={this.onClickAdd} />
					) : null}
					<Button text='Kirjaudu ulos' onClick={this.onAppLogout} />
				</div>
			</Menu>
		)
	}
	renderMenu = data => {
		const { pathname } = location
		if (!data || data.length === 0) {
			return null
		}

		return (
			<>
				{data.map((item, key) => {
					const isActive =
						pathname === item.path || pathname.includes(item.path)
					return (
						<MenuItemComponent
							content={item.content}
							key={key}
							path={item.path}
							subMenu={item.subMenu}
							infoSubMenu={item.infoSubMenu}
							id={item.id}
							notification={item.notification}
							done={item.done}
							progress={item.progress}
							isActive={isActive}
							canExtend={item.canExtend}
							onClickMenu={this.onLinkSelect}
						/>
					)
				})}
			</>
		)
	}

	render() {
		const { open, isAdmin } = this.props
		const sidebarClassName = ['sidebar']
		const menu = this.getMenu()
		return (
			<div className={sidebarClassName.join(' ')}>
				<div className={open ? 'sidebar__content' : 'sidebar__content hide'}>
					<img src={companyLogo} alt='koppo logo' />
					<div className='nav'>{this.renderMenu(menu)}</div>
					<div className='buttons'>
						{isAdmin ? (
							<Button text='LISÄÄ TYÖNTEKIJÄ' onClick={this.onClickAdd} />
						) : null}
						<Button text='Kirjaudu ulos' onClick={this.onAppLogout} />
					</div>{' '}
				</div>
				<div className='sidebar__burger-button'>
					{this.renderDropdownMenu()}
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	isAdmin: userSelectors.getIsAdmin(state.user)
})
export default connect(mapStateToProps)(withRouter(SideBar))
