/**
 * Login action
 *
 * @author Sravanthi Bandaru<sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { loginTypes as types } from 'actions/types'

const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}
const login = data => ({
	type: types.login,
	payload: {
		request: {
			method: 'POST',
			url: '/auth/signin',
			data
		}
	}
})

const resetFields = () => {
	return {
		type: types.resetFields
	}
}
const resetCredentials = () => {
	return {
		type: types.resetCredentials
	}
}
const resetError = () => {
	return {
		type: types.resetError
	}
}
export const loginActions = {
	changeField,
	login,
	resetError,
	resetFields,
	resetCredentials
}
