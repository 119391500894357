/**
 * Form validation util methods
 *
 * @author Sravanthi <sravanthi@vertics.co>
 *
 */
// email validation
export const isEmailValid = value =>
	value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)

// password length validation
export const isPasswordValid = (value, length) =>
	value && value.length >= length
