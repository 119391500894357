export const responseValidator = (response, successfulStatus = 200) => {
	return response.payload && response.payload.status === successfulStatus
}
export const apiWrapper = (actionType, requestConfig, isSecure = true) => (
	dispatch,
	getState
) => {
	const action = {
		type: actionType,
		payload: {
			request: {
				...requestConfig,
				url: requestConfig.url,
				headers: {
					...requestConfig.headers,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache'
				}
			}
		}
	}
	if (isSecure) {
		const { request } = action.payload
		request.headers = {
			...request.headers
			// bearer: state.user.bearer
		}
	}
	return dispatch(action)
}
