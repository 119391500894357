/**
 * Declare all types and export
 *
 * @author Sravanthi Bandaru  <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */

// Login
export const loginTypes = {
	changeField: 'LOGIN_CHANGE_FIELD',
	login: 'LOGIN',
	loginSuccess: 'LOGIN_SUCCESS',
	loginFail: 'LOGIN_FAIL',
	tokenRefresh: 'TOKEN_REFRESH',
	tokenRefreshSuccess: 'TOKEN_REFRESH_SUCCESS',
	tokenRefreshFail: 'TOKEN_REFRESH_FAIL',
	login: 'LOGIN',
	loginSuccess: 'LOGIN_SUCCESS',
	loginFail: 'LOGIN_FAIL',
	resetError: 'LOGIN_RESET_ERROR',
	resetFields: 'LOGIN_RESET_FIELDS',
	resetCredentials: 'LOGIN_RESET_CREDENTIALS'
}
// Admin User
export const adminUserTypes = {
	addUser: 'ADMIN_USER_ADD_USER',
	addUserSuccess: 'ADMIN_USER_ADD_USER_SUCCESS',
	addUserFail: 'ADMIN_USER_ADD_USER_FAIL',
	changeField: 'ADMIN_USER_CHANGE_FIELD',
	getAllEmployees: 'ADMIN_USER_GET_ALL_EMPLOYEES',
	getAllEmployeesSuccess: 'ADMIN_USER_GET_ALL_EMPLOYEES_SUCCESS',
	getAllEmployeesFail: 'ADMIN_USER_GET_ALL_EMPLOYEES_FAIL',
	getSelectedEmployee: 'ADMIN_GET_SELECTED_EMPLOYEE',
	getSelectedEmployeeSuccess: 'ADMIN_GET_SELECTED_EMPLOYEE_SUCCESS',
	getSelectedEmployeeFail: 'ADMIN_GET_SELECTED_EMPLOYEE_FAIL',
	changeUserStatus: 'ADMIN_CHANGE_USER_STATUS',
	changeUserStatusSuccess: 'ADMIN_CHANGE_USER_STATUS_SUCCESS',
	changeUserStatusFail: 'ADMIN_CHANGE_USER_STATUS_FAIL',
	editEmployee: 'ADMIN_EDIT_EMPLOYEE',
	editEmployeeSuccess: 'ADMIN_EDIT_EMPLOYEE_SUCCESS',
	editEmployeeFail: 'ADMIN_EDIT_EMPLOYEE_FAIL',
	changeFormField: 'ADMIN_USER_CHANGE_FORM_FIELD',
	resetError: 'ADMIN_USER_RESET_ERROR',
	resetFields: 'ADMIN_USER_RESET_FIELDS',
	resetCredentials: 'ADMIN_USER_RESET_CREDENTIALS'
}
// Password
export const passwordTypes = {
	resetPassword: 'PASSWORD_RESET',
	resetPasswordSuccess: 'PASSWORD_RESET_SUCCESS',
	resetPasswordFail: 'PASSWORD_RESET_FAIL',
	setPassword: 'PASSWORD_SET',
	setPasswordSuccess: 'PASSWORD_SET_SUCCESS',
	setPasswordFail: 'PASSWORD_SET_FAIL',
	changeField: 'PASSWORD_CHANGE_FIELD',
	resetFields: 'PASSWORD_RESET_FIELDS',
	resetError: 'PASSWORD_RESET_ERROR'
}
// User
export const userTypes = {
	getUser: 'USER_GET_USER',
	getUserSuccess: 'USER_GET_USER_SUCCESS',
	getUserFail: 'USER_GET_USER_FAIL',
	updateInfo: 'USER_UPDATE_INFO',
	updateInfoSuccess: 'USER_UPDATE_INFO_SUCCESS',
	updateInfoFail: 'USER_UPDATE_INFO_FAIL',
	changePassword: 'USER_CHANGE_PASSWORD',
	changePasswordSuccess: 'USER_CHANGE_PASSWORD_SUCCESS',
	changePasswordFail: 'USER_CHANGE_PASSWORD_FAIL',
	getNewJWT: 'USER_GET_NEW_JWT',
	getNewJWTSuccess: 'USER_GET_NEW_JWT_SUCCESS',
	getNewJWTFail: 'USER_GET_NEW_JWT_FAIL',
	resetFields: 'USER_RESET_FIELDS',
	changeField: 'USER_CHANGE_FIELD',
	logout: 'USER_LOGOUT',
	logoutSuccess: 'USER_LOGOUT_SUCCESS',
	logoutFail: 'USER_LOGOUT_FAIL',
	resetFields: 'USER_RESET_FIELDS'
}

// Check Info 
export const checkInfoTypes = {
	getLoggedHours: 'CHECK_INFO_GET_LOGGED_HOURS',
	getLoggedHoursSuccess: 'CHECK_INFO_GET_LOGGED_HOURS_SUCCESS',
	getLoggedHoursFail: 'CHECK_INFO_GET_LOGGED_HOURS_FAIL',
	changeField: 'CHECK_INFO_CHANGE_FIELD',
	resetError: 'CHECK_INFO_RESET_ERROR',
	resetFields: 'CHECK_INFO_RESET_FIELDS'
}

// Log hours
export const logHoursTypes = {
	changeField: 'LOG_HOURS_CHANGE_FIELD',
	resetFields: 'LOG_HOURS_RESET_FIELDS',
	resetError: 'LOG_HOURS_RESET_ERROR',
	createLoggedHours: 'LOG_HOURS_CREATE_HOURS',
	createLoggedHoursSuccess: 'LOG_HOURS_CREATE_HOURS_SUCCESS',
	createLoggedHoursFail: 'LOG_HOURS_CREATE_HOURS_FAIL',
	deleteLogHour: 'LOG_HOURS_DELETE_LOG_HOUR',
	deleteLogHourSuccess: 'LOG_HOURS_DELETE_LOG_HOUR_SUCCESS',
	deleteLogHourFail: 'LOG_HOURS_DELETE_LOG_HOUR_FAIL',
	getAvailableSalaryPeriods: 'LOG_HOURS_GET_AVAILABLE_SALARY_PERIODS',
	getAvailableSalaryPeriodsSuccess: 'LOG_HOURS_GET_AVAILABLE_SALARY_PERIODS_SUCCESS',
	getAvailableSalaryPeriodsFail: 'LOG_HOURS_GET_AVAILABLE_SALARY_PERIODS_FAIL',
	updateLoggedHours: 'LOG_HOURS__UPDATE_LOGGED_HOURS',
	updateLoggedHoursSuccess: 'LOG_HOURS__UPDATE_LOGGED_HOURS_SUCCESS',
	updateLoggedHoursFail: 'LOG_HOURS__UPDATE_LOGGED_HOURS_FAIL',
	resetForm: 'LOG_HOURS__RESET_FORM'

}
// Files
export const fileTypes = {
	fileUpload: 'FILE_UPLOAD',
	fileUploadSuccess: 'FILE_UPLOAD_SUCCESS',
	fileUploadFail: 'FILE_UPLOAD_FAIL'
}
// Admin Log Hours

export const adminLogHoursTypes = {
	changeField: 'ADMIN_LOG_HOURS_CHANGE_FIELD',
	resetFields: 'ADMIN_LOG_HOURS_RESET_FIELDS',
	resetError: 'ADMIN_LOG_HOURS_RESET_ERROR',
	getAwaitedLogHours: 'ADMIN_GET_AWAITED_LOG_HOURS',
	getAwaitedLogHoursSuccess: 'ADMIN_GET_AWAITED_LOG_HOURS_SUCCESS',
	getAwaitedLogHoursFail: 'ADMIN_GET_AWAITED_LOG_HOURS_FAIL',
	getAllSalaryPeriods: 'ADMIN_GET_ALL_SALARY_PERIODS',
	getAllSalaryPeriodsSuccess: 'ADMIN_GET_ALL_SALARY_PERIODS_SUCCESS',
	getAllSalaryPeriodsFail: 'ADMIN_GET_ALL_SALARY_PERIODS_FAIL',
	getTotalUsersLoggedHours: 'ADMIN_GET_TOTAL_USERS_LOG_HOURS',
	getTotalUsersLoggedHoursSuccess: 'ADMIN_GET_TOTAL_USERS_LOG_HOURS_SUCCESS',
	getTotalUsersLoggedHoursFail: 'ADMIN_GET_TOTAL_USERS_LOG_HOURS_FAIL',
	acceptLogHours: 'ADMIN_LOG_HOURS_ACCEPT',
	acceptLogHoursSuccess: 'ADMIN_LOG_HOURS_ACCEPT_SUCCESS',
	acceptLogHoursFail: 'ADMIN_LOG_HOURS_ACCEPT_FAIL',
	rejectLogHours: 'ADMIN_LOG_HOURS_REJECT',
	rejectLogHoursSuccess: 'ADMIN_LOG_HOURS_REJECT_SUCCESS',
	rejectLogHoursFail: 'ADMIN_LOG_HOURS_REJECT_FAIL',
	getSelectedUserLogHours: 'ADMIN_GET_SELECTED_EMPLOYEE_LOG_HOURS',
	getSelectedUserLogHoursSuccess: 'ADMIN_GET_SELECTED_EMPLOYEE_LOG_HOURS_SUCCESS',
	getSelectedUserLogHoursFail: 'ADMIN_GET_SELECTED_EMPLOYEE_LOG_HOURS_FAIL',
	getUserLogHoursSummary: 'ADMIN_GET_USER_LOG_HOURS_SUMMARY',
	getUserLogHoursSummarySuccess: 'ADMIN_GET_USER_LOG_HOURS_SUMMARY_SUCCESS',
	getUserLogHoursSummaryFail: 'ADMIN_GET_USER_LOG_HOURS_SUMMARY_FAIL',
	editLogHours: 'ADMIN_EDIT_LOG_HOURS',
	editLogHoursSuccess: 'ADMIN_EDIT_LOG_HOURS_SUCCESS',
	editLogHoursFail: 'ADMIN_EDIT_LOG_HOURS_FAIL',
	createTempUserLogHours: 'ADMIN_CREATE_TEMP_USER_LOG_HOURS',
	createTempUserLogHoursSuccess: 'ADMIN_CREATE_TEMP_USER_LOG_HOURS_SUCCESS',
	createTempUserLogHoursFail: 'ADMIN_CREATE_TEMP_USER_LOG_HOURS_FAIL',
	deleteLogHour: 'ADMIN_LOG_HOURS_DELETE_LOG_HOUR',
	deleteLogHourSuccess: 'ADMIN_LOG_HOURS_DELETE_LOG_HOUR_SUCCESS',
	deleteLogHourFail: 'ADMIN_LOG_HOURS_DELETE_LOG_HOUR_FAIL'
}

// Admin report
export const adminReportTypes = {
	getReport: 'ADMIN_GET_REPORT',
	getReportSuccess: 'ADMIN_GET_REPORT_SUCCESS',
	getReportFail: 'ADMIN_GET_REPORT_FAIL',
	getAllReport: 'ADMIN_GET_ALL_REPORT',
	getAllReportSuccess: 'ADMIN_GET_ALL_REPORT_SUCCESS',
	getAllReportFail: 'ADMIN_GET_ALL_REPORT_FAIL',
	getSelectedReport: 'ADMIN_GET_SELECTED_REPORT',
	getSelectedReportSuccess: 'ADMIN_GET_SELECTED_REPORT_SUCCESS',
	getSelectedReportFail: 'ADMIN_GET_SELECTED_REPORT_FAIL',
	resetFields: 'ADMIN_REPORT_RESET_FIELDS'
}
