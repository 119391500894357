/**
 * Login action
 *
 * @author Sravanthi Bandaru<sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { passwordTypes as types } from 'actions/types'
import { store } from '../store'
const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}
const resetPassword = (data) => ({
	type: types.resetPassword,
	payload: {
		request: {
			method: 'GET',
			url: `/auth/password/forgot`,
			params: {
				email: data
			}
		}
	}
})
const setPassword = (data) => {
	const resetKey = store.getState().password.resetKey
	return {
		type: types.setPassword,
		payload: {
			request: {
				method: 'POST',
				url: `/auth/password/change`,
				data,
				headers: {
					authorization: `Bearer ${resetKey}`
				}
			}
		}
	}
}

const resetFields = () => {
	return {
		type: types.resetFields
	}
}
const resetError = () => {
	return {
		type: types.resetError
	}
}

export const passwordActions = {
	changeField,
	resetFields,
	resetError,
	resetPassword,
	setPassword
}
