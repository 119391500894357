/**
 * Generic Input component
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import React from 'react'

class Input extends React.Component {
	state = {
		focusClass: ''
	}

	renderLabel = () => (
		<label className='input-field__label'>{this.props.label}</label>
	)

	handleClickInputIcon = name => {
		const { handleClickInputIcon, disabled = false } = this.props
		if (
			handleClickInputIcon &&
			typeof handleClickInputIcon === 'function' &&
			!disabled
		) {
			handleClickInputIcon(name)
		}
	}

	handleFocusInput = () => {
		this.setState({ focusClass: 'focus' })
		const { onInputFocus } = this.props
		if (onInputFocus && typeof onInputFocus === 'function') {
			onInputFocus()
		}
	}

	handleBlurInput = e => {
		this.setState({ focusClass: '' })
		const { handleBlurInput, item } = this.props
		if (handleBlurInput && typeof handleBlurInput === 'function') {
			handleBlurInput(e, item)
		}
	}
	onIconClick = () => {
		const { onFieldEdit, item, iconName, onIconClick } = this.props
		if (iconName === 'save') {
			onFieldEdit(item, false)
			return
		}
		if (iconName === 'edit') {
			onFieldEdit(item, true)
			return
		}
		if (iconName === 'search') {
			onIconClick()
		}
		this.handleClickInputIcon('send')
	}

	renderIcon = () => {
		const { icon, iconName, name, value } = this.props

		if (iconName) {
			return (
				<i
					className='material-icons'
					onClick={this.onIconClick}
					name={name}
					value={value}
				>
					{iconName}
				</i>
			)
		}

		if (icon) {
			return icon
		}

		return null
	}
	_onChange = e => {
		const { onChange, item } = this.props
		if (onChange && typeof onChange === 'function') {
			onChange(e, item)
		}
	}
	render() {
		const {
			type,
			label,
			disabled,
			autoFocus,
			value,
			placeholder,
			name,
			className,
			withSymbol,
			symbol,
			icon,
			onIconClick,
			reference,
			id,
			inputProps,
			hasError,
			error,
			labelShown,
			step,
			pattern
		} = this.props

		const inputClass = ['input-field']
		if (className) {
			inputClass.push(className)
		}

		if (this.state.focusClass) {
			inputClass.push(this.state.focusClass)
		}
		if (disabled) {
			inputClass.push('disabled')
		}
		return (
			<div className={inputClass.join(' ')}>
				{label && labelShown && this.renderLabel()}
				<div className='input-field__input-content'>
					<input
						type={type ? type : 'text'}
						name={name}
						value={value ? value : ''}
						placeholder={placeholder}
						onChange={this._onChange}
						disabled={disabled}
						ref={reference}
						id={id}
						{...inputProps}
						onFocus={this.handleFocusInput}
						onBlur={this.handleBlurInput}
						autoFocus={autoFocus}
						step={step}
						max={name === 'minutes' && 59}
						pattern={pattern && pattern}
					/>
					{withSymbol && (
						<div className='input-field__input-symbol'>{symbol}</div>
					)}
					<div className='input-field__icon-container'>{this.renderIcon()}</div>
					{icon && (
						<span className='material-icons' onClick={onIconClick}>
							{icon}
						</span>
					)}
				</div>
				{hasError ? <span className='error'>{error}</span> : null}
			</div>
		)
	}
}

export default Input
