import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Checkbox from '@material-ui/core/Checkbox'
import { primaryColor } from '../../constants'
// Checkbox will be at the start of the view
const CustomCheckbox = withStyles({
	root: {
		color: primaryColor,
		'&$checked': {
			color: primaryColor
		}
	},
	checked: {}
})(props => <Checkbox color='default' {...props} />)
export default ({
	checked,
	onChange,
	label,
	name,
	value,
	containerClassName,
	color = 'primary',
	disabled,
	...props
}) => {
	const containerClass = containerClassName
		? [containerClassName].concat(['checkbox__container'])
		: ['checkbox__container']
	return (
		<label className={containerClass.join(' ')}>
			<CustomCheckbox
				checked={checked}
				onChange={onChange}
				value={value}
				name={name}
				color={color}
				{...props}
				disabled={disabled}
			/>
			<span className='checkbox-label'>{label !== '' && label}</span>
		</label>
	)
}
