/**
 * Admin log hours reducer
 *
 * @author sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { adminLogHoursTypes as types } from '../actions/types'
import moment from 'moment'
import { normalizeError, getCurrentSalaryPeriod, getCurrentPaymentPeriod } from './utils'

const initialState = {
    loading: false,
    error: null,
    dateRange: [moment(), moment()],
    awaitedLogHours: [],
    totalLoggedHours: [],
    selectedShift: null,
    needToAccept: false,
    selectedUserLogHours: [],
    userLogHoursSummary: null,
    selectedType: null,
    salaryPeriods: [],
    selectedSalaryRange: getCurrentPaymentPeriod(),
    currentSalaryPeriod: {
        startDate: moment(),
        endDate: moment()
    }

}

export const adminLogHoursReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.changeField:
            return {
                ...state,
                [action.name]: action.value,
                error: initialState.error
            }
        case types.getAwaitedLogHours:
            return {
                ...state,
                loading: true
            }

        case types.getAwaitedLogHoursSuccess:
            return {
                ...state,
                awaitedLogHours: action.payload.data,
                loading: false
            }

        case types.getAwaitedLogHoursFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        case types.getAllSalaryPeriods:
            return {
                ...state,
                loading: true
            }

        case types.getAllSalaryPeriodsSuccess:
            const currentSalaryPeriod = getCurrentSalaryPeriod(action.payload.data)
            return {
                ...state,
                salaryPeriods: action.payload.data,
                currentSalaryPeriod,
                loading: false
            }

        case types.getAllSalaryPeriodsFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        case types.getTotalUsersLoggedHours:
            return {
                ...state,
                loading: true
            }

        case types.getTotalUsersLoggedHoursSuccess:
            return {
                ...state,
                totalLoggedHours: action.payload.data,
                loading: false
            }

        case types.getTotalUsersLoggedHoursFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        case types.getSelectedUserLogHours:
            return {
                ...state,
                loading: true
            }

        case types.getSelectedUserLogHoursSuccess:
            return {
                ...state,
                selectedUserLogHours: action.payload.data,
                loading: false
            }

        case types.getSelectedUserLogHoursFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        case types.getUserLogHoursSummary:
            return {
                ...state,
                loading: true
            }

        case types.getUserLogHoursSummarySuccess:
            return {
                ...state,
                userLogHoursSummary: action.payload.data,
                loading: false
            }

        case types.getUserLogHoursSummaryFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        case types.deleteLogHour:
            return {
                ...state,
                loading: true
            }

        case types.deleteLogHourSuccess:
            return {
                ...state,
                loading: false
            }

        case types.deleteLogHourFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }

        case types.acceptLogHours:
        case types.rejectLogHours:
        case types.editLogHours:
        case types.createTempUserLogHours:
            return {
                ...state,
                loading: true
            }

        case types.acceptLogHoursSuccess:
        case types.rejectLogHoursSuccess:
        case types.editLogHoursSuccess:
        case types.createTempUserLogHoursSuccess:
            return {
                ...state,
                loading: false
            }
        case types.acceptLogHoursFail:
        case types.rejectLogHoursFail:
        case types.editLogHoursFail:
        case types.createTempUserLogHoursFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        case types.resetFields:
            return {
                ...initialState
            }
        case types.resetError:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const adminLogHoursSelectors = {
    getAwaitedLogHours: (state = initialState) => state.awaitedLogHours,
    getTotalLoggedHours: (state = initialState) => state.totalLoggedHours,
    getNeedToAccept: (state = initialState) => state.needToAccept,
    getSelectedUserLogHours: (state = initialState) => state.selectedUserLogHours,
    getUserLogHoursSummary: (state = initialState) => state.userLogHoursSummary,
    getSelectedType: (state = initialState) => state.selectedType,
    getSalaryPeriods: (state = initialState) => state.salaryPeriods,
    getCurrentSalaryPeriod: (state = initialState) => state.currentSalaryPeriod,
    getSelectedSalaryRange: (state = initialState) => state.selectedSalaryRange,
    getError: (state = initialState) => state.error
}
