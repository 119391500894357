/**
 * Login reducer
 *
 * @author Sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { loginTypes as types } from '../actions/types'
import { normalizeError } from './utils'

const initialState = {
	token: null,
	refreshToken: null,
	email: null,
	password: null,
	loading: false,
	error: null
}

export const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeField:
			return {
				...state,
				[action.name]: action.value,
				error: initialState.error
			}
		case types.resetFields:
			return {
				...initialState
			}
		case types.resetCredentials:
			return {
				...state,
				email: null,
				password: null,
				error: null,
				loading: false
			}
		case types.resetError:
			return {
				...state,
				error: null
			}
		case types.login:
			return {
				...state,
				loading: true
			}

		case types.loginSuccess:
			const { token, refreshToken } = action.payload.data
			localStorage.setItem('token', token)
			localStorage.setItem('refreshToken', refreshToken)
			return {
				...state,
				token,
				refreshToken,
				loading: false
			}

		case types.loginFail:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case types.tokenRefresh:
			return {
				...state,
				loading: true
			}

		case types.tokenRefreshSuccess:
			localStorage.setItem('token', action.payload.data.token)
			return {
				...state,
				token: action.payload.data.token
			}

		case types.tokenRefreshFail:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		default:
			return state
	}
}

export const loginSelectors = {
	getField: (state = initialState, field) => state[field],
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error,
	getEmail: (state = initialState) => state.email
}
