/**
 * Admin user action
 *
 * @author Sravanthi Bandaru<sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { adminReportTypes as types } from 'actions/types'
import Qs from 'qs'

const getReport = (id, params) => ({
	type: types.getReport,
	payload: {
		request: {
			method: 'GET',
            url: `/admin/reports/users/${id}`,
            params
		}
	}
})
const getAllReport = (params) => ({
	type: types.getAllReport,
	payload: {
		request: {
			method: 'GET',
            url: `/admin/reports/all`,
            params
		}
	}
})
const getSelectedReport = (params) => ({
	type: types.getSelectedReport,
	payload: {
		request: {
			method: 'GET',
            url: `/admin/reports/multiple-users`,
            params,
			paramsSerializer: function (params) {
                return Qs.stringify(params, {arrayFormat: 'brackets'})
              }

		}
	}
})
const resetFields = () => {
    return {
        type: types.resetFields
    }
}


export const adminReportActions = {
	getReport,
	getAllReport,
	getSelectedReport,
	resetFields
}
