/**
 * Log hours action
 *
 * @author Sravanthi Bandaru<sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { logHoursTypes as types } from 'actions/types'

const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}
const createLoggedHours = (data) => ({
	type: types.createLoggedHours,
	payload: {
		request: {
			method: 'POST',
			url: `/logged-hours`,
			data
		}
	}
})
const deleteLogHour = (id) => ({
	type: types.deleteLogHour,
	payload: {
		request: {
			method: 'DELETE',
			url: `/logged-hours/${id}`
				}
	}
})
const updateLoggedHours = (id, data) => ({
	type: types.updateLoggedHours,
	payload: {
		request: {
			method: 'PATCH',
			url: `/logged-hours/${id}`,
			data
		}
	}
})
const getAvailableSalaryPeriods = (userId) => ({
	type: types.getAvailableSalaryPeriods,
	payload: {
		request: {
			method: 'GET',
			url: `/logged-hours/payment-period/users/${userId}`,
			params: {
				userId
			}
		}
	}
})

const resetFields = () => {
	return {
		type: types.resetFields
	}
}
const resetError = () => {
	return {
		type: types.resetError
	}
}
const resetForm = () => {
    return {
        type: types.resetForm
    }
}

export const logHoursActions = {
	changeField,
	resetError,
	resetFields,
	createLoggedHours,
	updateLoggedHours,
	deleteLogHour,
	getAvailableSalaryPeriods,
	resetForm
}
