import React, { Component } from 'react'
import { connect } from 'react-redux'
// Actions and reducers
import { fileActions, userActions, adminUserActions } from 'actions'
import { userSelectors, adminUserSelectors } from 'reducers'
// Components
import Avatar from 'shared/components/Avatar'
import AttachmentPicker from 'shared/components/AttachementPicker'
import Checkbox from 'shared/components/Checkbox'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'
import withLoadingScreen from 'shared/components/Loading'

// Utils
import { getFields, getErrorArr } from './utils'
import { isPasswordValid } from 'shared/utils/form'

import {
	userRole,
	DEACTIVATED_STATUS,
	MIN_PASSWORD_LENGTH,
	SUCCESS_STATE_CODE
} from 'constants'
import { getTotalTime } from 'shared/utils/time'

class Profile extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fields: null,
			errorArr: [],
			isDelete: false,
			isDeleted: false,
			isUpdated: false,
			isOwnProfile: true,
			isPasswordChange: false,
			isPasswordChanged: false,
			isPasswordError: {
				password: false,
				rePassword: false
			}
		}
	}
	async componentDidMount() {
		const { isAdmin, user, selectedEmployee, match } = this.props
		let employee = user
		if (match.params.id) {
			const res = await this.props.dispatch(
				adminUserActions.getSelectedEmployee(parseInt(match.params.id))
			)
			if (res.payload.data) {
				employee = res.payload.data
				this.setState({ isOwnProfile: false })
			}
		}
		if (user.id === employee.id) {
			this.setState({ isOwnProfile: true })
		}
		let hours = Math.floor(employee.bankHours)
		let minutes = Math.round((employee.bankHours - hours) * 60)
		if (minutes === 60) {
			hours = hours + 1
			minutes = 0
		}
		employee = {
			...employee,
			hours,
			minutes
		}

		this.props.dispatch(userActions.changeField('selectedUser', employee))
		const fields = getFields(employee, user, isAdmin)
		this.setState({ fields })
	}
	componentWillUnmount() {
		this.props.dispatch(userActions.changeField('selectedUser', null))
		this.props.dispatch(adminUserActions.changeField('selectedEmployee', null))
		this.props.dispatch(userActions.changeField('password', ''))
		this.props.dispatch(userActions.changeField('rePassword', ''))
	}
	onFileUpload = async (attachment, result, name) => {
		const data = new FormData()
		data.append('file', attachment)
		const res = await this.props.dispatch(fileActions.fileUpload(data))
		if (res.payload.data) {
			this.props.dispatch(
				userActions.changeField(name, res.payload.data, 'selectedUser')
			)
		}
	}
	isFormInValid = () => {
		const { selectedUser, isAdmin } = this.props
		const fields = getFields(selectedUser, selectedUser, isAdmin)
		const { editableFields } = fields
		const errorArr = getErrorArr(editableFields)
		this.setState({ errorArr })
		return errorArr.some(item => item.error)
	}
	isPasswordFormValid = () => {
		const { password, rePassword } = this.props
		const { isPasswordError } = this.state
		if (!isPasswordValid(password, MIN_PASSWORD_LENGTH)) {
			this.setState({
				isPasswordError: {
					...isPasswordError,
					password: true
				}
			})
			return false
		}
		if (rePassword !== password) {
			this.setState({
				isPasswordError: {
					...isPasswordError,
					rePassword: true
				}
			})
			return false
		}
		return true
	}
	onFieldChange = e => {
		const { name } = e.target
		let { value } = e.target
		if (name === 'hours' || name === 'minutes') {
			if (!value) {
				value = 0
			}
			if (!/^\d+$/.test(value)) {
				return
			}

			value = parseInt(value)
		}
		const { errorArr } = this.state
		if (value) {
			this.setState({ errorArr: errorArr.filter(item => item.name !== name) })
		}
		if (name === 'password' || name === 'rePassword') {
			if (value) {
				this.setState({
					isPasswordError: {
						password: false,
						rePassword: false
					}
				})
			}
			this.props.dispatch(userActions.changeField(name, value))
		} else {
			if (name === 'minutes' && value >= 60) {
				return
			}
			this.props.dispatch(userActions.changeField(name, value, 'selectedUser'))
		}
	}
	onCheckboxChange = e => {
		const { name, value } = e.target
		const { selectedUser } = this.props
		this.props.dispatch(
			userActions.changeField(
				name,
				selectedUser.role === userRole.TEMP
					? userRole.PREMANENT
					: userRole.TEMP,
				'selectedUser'
			)
		)
	}
	onModalClose = () => {
		this.setState({
			isDelete: false,
			isDeleted: false,
			isUpdated: false,
			isPasswordChange: false,
			isPasswordChanged: false,
			isPasswordError: {
				password: false,
				rePassword: false
			}
		})
		this.props.dispatch(userActions.changeField('password', ''))
		this.props.dispatch(userActions.changeField('rePassword', ''))
	}
	OnEmployeeUpdate = async () => {
		if (this.isFormInValid()) {
			return
		}
		const { isOwnProfile } = this.state
		const {
			selectedUser,
			isAdmin,
			dispatch,
			user,
			showLoading,
			hideLoading
		} = this.props
		let { hours } = selectedUser
		let { minutes } = selectedUser
		let userClone = { ...selectedUser }

		let normalizeMinutes = 0
		if (minutes > 0 && minutes <= 15) {
			normalizeMinutes = 25
		}
		if (minutes > 15 && minutes <= 30) {
			normalizeMinutes = 50
		}
		if (minutes > 30 && minutes <= 45) {
			normalizeMinutes = 75
		}
		if (minutes > 45 && minutes <= 59) {
			normalizeMinutes = 0
			minutes = 0
			hours = parseInt(hours) + 1
		}
		const bankHours = parseInt(hours) + parseInt(normalizeMinutes) / 100
		delete userClone.hours
		delete userClone.minutes
		userClone = { ...userClone, bankHours: bankHours }

		if (userClone.avatar === undefined) {
			userClone.avatar = null
		}
		try {
			showLoading()
			const res = await dispatch(
				adminUserActions.editEmployee(selectedUser.id, userClone)
			)
			if (res.payload.data) {
				const updatedResponse = {
					...res.payload.data,
					hours,
					minutes: minutes
				}
				this.props.dispatch(
					userActions.changeField('selectedUser', updatedResponse)
				)
				isOwnProfile &&
					this.props.dispatch(userActions.changeField('user', updatedResponse))
				this.setState({ isUpdated: true })
			}
		} catch (e) {
		} finally {
			hideLoading()
		}
	}
	onConfirmingDelete = (e, value) => {
		e.stopPropagation()
		this.setState({ isDelete: true, selectedEmployee: value })
	}
	isUpdateToggle = value => {
		this.setState({ isUpdated: value })
	}
	onChangePassworsLinkToggle = value => {
		this.setState({ isPasswordChange: value })
	}
	onEmployeeDelete = async e => {
		e.stopPropagation()
		const { selectedUser } = this.props
		const res = await this.props.dispatch(
			adminUserActions.changeUserStatus(selectedUser.id, DEACTIVATED_STATUS)
		)
		if (res.payload.data) {
			this.setState({
				isDelete: false,
				isDeleted: true,
				isUpdated: true
			})
		}
	}
	renderField = field => {
		const { selectedUser, isAdmin } = this.props
		const { isDeleted } = this.state
		if (!selectedUser) {
			return null
		}
		switch (field.type) {
			case 'checkbox':
				return (
					<Checkbox
						name={field.name}
						label={field.label}
						checked={selectedUser[field.name] === userRole.TEMP}
						value={selectedUser[field.name]}
						onChange={this.onCheckboxChange}
						disabled={isDeleted}
					/>
				)
			default:
				return (
					<Input
						value={selectedUser[field.name]}
						name={field.name}
						type={field.type}
						onChange={this.onFieldChange}
						disabled={isDeleted}
						step={field.step}
						max={field.max}
						min={field.min}
						pattern={field.pattern}
					/>
				)
		}
	}
	renderEditableFields = () => {
		const { fields, errorArr } = this.state
		if (!fields) {
			return null
		}
		const { editableFields } = fields
		return (
			<div className='profile__editableFields'>
				{editableFields.map((field, key) => (
					<div className='profile__editableFields-field' key={key}>
						{field.type === 'checkbox' ? null : <label>{field.label}</label>}
						{this.renderField(field)}
						{errorArr.map((err, key) => (
							<p className='error' key={key}>
								{err.name === field.name ? err.error : null}
							</p>
						))}
					</div>
				))}
			</div>
		)
	}
	renderAvatarSection = () => {
		const { selectedUser, isAdmin, user } = this.props
		if (!selectedUser) {
			return null
		}
		const { isOwnProfile } = this.state
		const {
			avatar,
			firstName,
			lastName,
			streetAddress,
			postalCode,
			city,
			socialSecurityNumber
		} = selectedUser
		return (
			<div className='profile__normalFields'>
				<div className='profile__normalFields-avatarSection'>
					<Avatar
						id={avatar && avatar.id ? avatar.id : null}
						imageKey={avatar && avatar.key ? avatar.key : null}
						isEditable={true}
					/>
					{user.id === selectedUser.id || !isAdmin ? (
						<AttachmentPicker
							icon='add'
							upload={true}
							name='avatar'
							handlePickAttachment={this.onFileUpload}
						/>
					) : null}
				</div>

				<div className='profile__normalFields-nameSection'>
					<span className='name'>
						{firstName} {lastName}
					</span>
					{isOwnProfile ? null : (
						<>
							<span>{streetAddress ? streetAddress : null}</span>
							<span>
								{postalCode ? postalCode : null} {city ? city : null}
							</span>
							<p className='ssn'>
								<span className='ssn__text'>Henkilötunnus : </span>
								<span className='ssn__value'>{socialSecurityNumber}</span>
							</p>
						</>
					)}
				</div>
			</div>
		)
	}
	onPasswordChange = async () => {
		if (!this.isPasswordFormValid()) {
			return
		}
		const { password } = this.props
		const res = await this.props.dispatch(
			userActions.changePassword({ password })
		)
		if (res.payload.status === SUCCESS_STATE_CODE) {
			this.setState({ isPasswordChanged: true, isPasswordChange: false })
		}
	}
	renderModalContent = () => {
		const { error } = this.props
		const {
			isDelete,
			isDeleted,
			isUpdated,
			isPasswordChange,
			isPasswordChanged,
			isPasswordError
		} = this.state
		if (isDelete || isDeleted) {
			return isDelete ? (
				<div className='confirmation'>
					<p>Oletko varma että haluat poistaa käyttäjän?</p>
					<div className='buttons'>
						<Button text='Kyllä' onClick={this.onEmployeeDelete} />
						<Button
							text='Ei'
							onClick={() => this.setState({ isDelete: false })}
						/>
					</div>
				</div>
			) : (
				<p className='confirmation'>työntekijä poistettiin onnistuneesti</p>
			)
		} else if (isUpdated) {
			return (
				<div className='confirmation'>
					<p>Muutokset tallennettu!</p>
					<Button text='Ok' onClick={this.onModalClose} />
				</div>
			)
		} else if (isPasswordChanged || isPasswordChange) {
			const { password, rePassword, loading } = this.props
			const passwordFields = [
				{
					value: password,
					name: 'password',
					type: 'password',
					hasError: isPasswordError.password,
					error: 'Salasanan on vähintään oltava 8 merkkiä pitkä',
					placeholder: 'Salasana',
					label: 'Salasana',
					disabled: loading
				},
				{
					value: rePassword,
					name: 'rePassword',
					type: 'password',
					hasError: isPasswordError.rePassword,
					error: 'Salasanat eivät täsmää',
					placeholder: 'Salasana uudelleen',
					label: 'Salasana uudelleen',
					disabled: loading
				}
			]
			return isPasswordChange ? (
				<div className='confirmation'>
					{error ? <span className='error'>{error.msg}</span> : ''}
					{passwordFields.map((field, key) => (
						<Input
							value={field.value}
							name={field.name}
							onChange={this.onFieldChange}
							disabled={field.disabled}
							key={key}
							hasError={field.hasError}
							error={field.error}
							placeholder={field.placeholder}
							type={field.type}
						/>
					))}
					<div className='buttons'>
						<Button text='Talenna' onClick={this.onPasswordChange} />
						<Button text='Peruuta' onClick={this.onModalClose} />
					</div>
				</div>
			) : (
				<div className='confirmation'>
					<p>Salasana vaihdettu onnistuneesti</p>
					<Button text='Ok' onClick={this.onModalClose} />
				</div>
			)
		}
	}
	getDividedHours = time => {
		let hours = 0
		let minutes = 0
		if (time) {
			hours = Math.floor(time)
			minutes = Math.round((time - hours) * 60)
			if (minutes === 60) {
				hours = hours + 1
				minutes = 0
			}
		}
		return [
			{
				name: 'hours',
				value: hours,
				trailingText: 'T',
				type: 'number',
				step: 1
			},
			{
				name: 'minutes',
				value: minutes,
				trailingText: 'M',
				type: 'number',
				step: 15,
				max: 60
			}
		]
	}
	renderHoursDescription = () => {
		const { selectedUser, isAdmin, user } = this.props
		if (!selectedUser) {
			return null
		}
		const { unpaidLeave, bankHours } = selectedUser
		const hourFields = this.getDividedHours(bankHours)
		const field = {
			name: 'unpaidLeave',
			value: unpaidLeave,
			label: 'Pekkasvapaa',
			class: 'unpaidLeave',
			type: 'number',
			trailingText: 'Päivää'
		}

		return (
			<div className='profile__hoursDescription'>
				<div className='profile__hoursDescription-unpaidLeave'>
					<label>{field.label}</label>
					{isAdmin ? <>{this.renderField(field)}</> : field.value}
					<span className='trailingText'>{field.trailingText || ''}</span>
				</div>

				<div className='profile__hoursDescription-hoursFields'>
					<label>Tuntipankki</label>
					{hourFields.map((field, key) => {
						return (
							<div key={key} className='item'>
								{isAdmin ? <>{this.renderField(field)}</> : field.value}
								<span className='trailingText'>{field.trailingText || ''}</span>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
	render() {
		const { history, isAdmin, error, adminUserError } = this.props
		const {
			isDelete,
			isUpdated,
			isOwnProfile,
			isDeleted,
			isPasswordChange,
			isPasswordChanged
		} = this.state
		const isModalOpen =
			isDelete || isUpdated || isPasswordChange || isPasswordChanged
		return (
			<div className='profile'>
				<Button text='TAKAISIN' onClick={() => history.goBack()} />
				{this.renderAvatarSection()}
				{error ? <span className='error'>{error.msg}</span> : null}
				{adminUserError ? (
					<span className='error'>{adminUserError.msg}</span>
				) : null}
				{isOwnProfile ? (
					<span
						className='profile__changePassword'
						onClick={() => this.onChangePassworsLinkToggle(true)}
					>
						Vaihda salasana
					</span>
				) : null}
				{this.renderEditableFields()}
				{this.renderHoursDescription()}
				{isDeleted ? null : (
					<div className='profile__buttons'>
						{isAdmin && !isOwnProfile ? (
							<Button
								text='POISTA KÄYTTÄJÄ'
								onClick={this.onConfirmingDelete}
							/>
						) : null}

						<Button text='TALLENNA MUUTOKSET' onClick={this.OnEmployeeUpdate} />
					</div>
				)}

				{isModalOpen ? (
					<Modal
						show={isModalOpen}
						title='modal'
						onModalClose={this.onModalClose}
					>
						{this.renderModalContent()}
					</Modal>
				) : null}
			</div>
		)
	}
}
const mapStateToProps = state => ({
	user: userSelectors.getUser(state.user),
	error: userSelectors.getError(state.user),
	selectedUser: userSelectors.getSelectedUser(state.user),
	loading: userSelectors.getLoading(state.user),
	password: userSelectors.getPassword(state.user),
	rePassword: userSelectors.getRePassword(state.user),
	selectedEmployee: adminUserSelectors.getSelectedEmployee(state.adminUser),
	adminUserError: adminUserSelectors.getError(state.adminUser),
	isAdmin: userSelectors.getIsAdmin(state.user)
})

export default connect(mapStateToProps)(withLoadingScreen(Profile))
