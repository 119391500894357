/**
 * Define some frequent-use constants
 * for easier import inside components
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import React from 'react'
// Route
export const routerPath = {
	login: '/',
	forgotPassword: '/forgot-password',
	mainPage: '/dashboard/home',
	addHours: '/dashboard/add-hours',
	checkInfoList: '/dashboard/check-information',
	checkInfoHandle: '/dashboard/check-information/:id',
	setPassword: '/password/success',
	passwordFail: '/user/password/failed',
	adminMainPage: '/admin/dashboard/home',
	adminWorkhours: '/admin/dashboard/workhours',
	adminCheckWorkHour: '/admin/dashboard/workhours/check',
	adminCheckWorkHourEdit: '/admin/dashboard/employee-workHours/:id',
	adminAddWorkHour: '/admin/dashboard/tempUser/workhours-add',
	adminPendingWorkHour: '/admin/dashboard/workhours/pending-hours',
	adminUserManagement: '/admin/dashboard/user-management',
	adminAddEmployee: '/admin/dashboard/add-employee',
	employeeWorkHoursDetails: '/admin/dashboard/employee-workHours',
	ownProfile: '/dashboard/profile',
	userProfile: '/admin/dashboard/user-management/profile/:id',
	adminUserManagementProfile: '/admin/dashboard/user-management/profile'
}
export const pageTitle = {
	welcome: 'Welcome',
	login: 'Login',
	forgotPassword: 'Forgot Password',
	mainPage: 'Main Page',
	addHours: 'Add Hours',
	checkInfo: 'CheckInfo',
	setPassword: 'Set Password',
	passwordFail: 'Password Fail',
	adminMainPage: 'Admin Main Page',
	adminUserManagement: 'Admin User Management',
	adminAddEmployee: 'Add Employee',
	ownProfile: 'Profile',
	userProfile: 'User Profile',
	adminCheckWorkHour: 'Admin Checking Work Hours',
	adminPendingWorkHour: 'Admin Pending Work Hours',
	adminWorkhours: 'Admin Work Hours',
	adminCheckWorkHourEdit: 'Admin Checking Work Hours Edit'
}
// Languages
export const languages = {
	en: 'en',
	fi: 'fi'
}

// Path to images directory
export const IMAGE_PATH = `${process.cwd()}src/assets/images`
// Path to icons directory
export const ICON_PATH = `${process.cwd()}src/assets/icons`
// Key redux persist
export const PERSIST_KEY = 'root'

// Color code
export const primaryColor = '#fc4f00'

export const LOGIN_TEXT = 'Kirjaudu'
export const FORGOT_PASSWORD_TEXT = 'Salasana unohtunut?'
export const PASSWORD_SET_TEXT =
	'Kirjoita sähköpostiosoitteesi niin lähetämme ohjeet salasanan vaihtamiseksi'
export const FORGOT_PASSWORD_HEADER = 'Salasana unohtunut'
export const MIN_PASSWORD_LENGTH = 8
export const colors = {
	white: '#FFF'
}
const date = new Date()
export const LOG_HOURS = [
	{
		title: <i className='material-icons'>check</i>,
		start: date,
		end: date,
		hours: '8h'
	},
	{
		title: <i className='material-icons'>check</i>,
		start: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
		end: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
		hours: '8h'
	},
	{
		title: <i className='material-icons'>check</i>,
		start: new Date(Date.now() - 4 * 24 * 60 * 60 * 1000),
		end: new Date(Date.now() - 4 * 24 * 60 * 60 * 1000),
		hours: '8h'
	}
]

export const DATE_FIELDS = [
	{
		name: 'start',
		label: 'Aloitus',
		type: 'dateTime'
	},
	{
		name: 'end',
		label: 'Lopetus',
		type: 'dateTime'
	}
]
export const TIME_FIELDS = [
	{
		name: 'startTime',
		label: 'Aloitusaika',
		type: 'time'
	},
	{
		name: 'endTime',
		label: 'Lopetusaika',
		type: 'time'
	}
]
const CHECKBOX_DATA = [
	{
		name: 'no',
		label: 'Ei',
		type: 'checkbox'
	},

	{
		name: 'yes',
		label: 'Kyllä',
		type: 'checkbox'
	}
]
export const DAILY_ALLOWANCE_FIELDS = [
	{
		name: 'dailyAllowance',
		label: 'Päiväraha',
		type: 'checkbox',
		fields: CHECKBOX_DATA
	},
	{
		name: 'moreInfo',
		label: 'Lisätiedot',
		type: 'textarea'
	}
]
export const SWITCH_FIELDS = [
	{
		name: 'isPekkasVapaa',
		label: 'Lisää pekkasvapaa',
		type: 'switch'
	},
	{
		name: 'isNormalLeave',
		label: 'Lisää lomapäivä',
		type: 'switch'
	},
	{
		name: 'isSickLeave',
		label: 'Kirjaa sairasloma',
		type: 'switch'
	}
]
export const TEXT_AREA_ROWS = 6
// Backend url
export const KOPPO_BACKEND_URL =
	process.env.KOPPO_BASE_URL ||
	'https://backend-staging-dot-koppo-291007.ey.r.appspot.com'
export const KOPPO_IMAGE_URL =
	process.env.KOPPO_IMAGE_URL ||
	'https://koppo.ams3.digitaloceanspaces.com/development'

export const CHECK_INFO_DATE_FIELDS = [
	{
		name: 'salaryRange',
		label: 'Etsi palkkakauden mukaan',
		type: 'select'
	},
	{
		name: 'dateRange',
		label: 'Etsi päivämäärän mukaan',
		type: 'period'
	}
]

export const EMPLOYEE_MANAGEMENT_FIELDS = [
	{
		name: 'firstName',
		label: 'Etunimi',
		type: 'text'
	},
	{
		name: 'lastName',
		label: 'Sukunimi',
		type: 'text'
	},
	{
		name: 'socialSecurityNumber',
		label: 'Henkilötunnus',
		type: 'text'
	},
	{
		name: 'bankAccountNumber',
		label: 'Tilinumero',
		type: 'text'
	},
	{
		name: 'streetAddress',
		label: 'Osoite',
		type: 'text'
	},
	{
		name: 'phoneNumber',
		label: 'Puhelin',
		type: 'text'
	},
	{
		name: 'email',
		label: 'Sähköposti',
		type: 'email'
	},
	{
		name: 'postalCode',
		label: 'Postinumero',
		type: 'number'
	},
	{
		name: 'city',
		label: 'Kaupunki',
		type: 'text'
	},
	{
		name: 'tempUser',
		label: 'Väliaikainen/keikkatyöntekijä',
		type: 'checkbox'
	}
]
export const DATE_FORMAT = 'DD.MM.YYYY'
export const TIME_FORMAT = 'HH:mm'
export const DEACTIVATED_TEXT = 'deactivated'
export const ACTIVATED_TEXT = 'activated'
export const USER_STATUS_REQUESTED = 'REQUESTED'
export const USER_STATUS_USER_EDITED = 'USER_EDITED'
export const USER_STATUS_ACCEPTED = 'ACCEPTED'
export const FILTER_TYPE_AWAITED = 'awaited'
export const FILTER_TYPE_TEMPROLE = 'tempRole'
export const USER_ROLE_PREMANENT_USER = 'permanent_user'
export const userRole = {
	TEMP: 'temp_user',
	PREMANENT: 'permanent_user'
}
export const HOUR_TYPE_ENTRY = 'HOUR'
export const UNPAID_LEAVE_ENTRY = 'UNPAID_LEAVE'
export const PAID_LEAVE_ENTRY = 'PAID_LEAVE'
export const SICK_LEAVE_ENTRY = 'SICK_LEAVE'
export const UNPAID_LEAVE_ENTRY_FINNISH = 'Pekkasvapaa'
export const PAID_LEAVE_ENTRY_FINNISH = 'Lomapäivä'
export const SICK_LEAVE_ENTRY_FINNISH = 'Sairasloma'
export const LEAVE_TYPE_ENTRY = 'LEAVE'
export const USER_ROLE_TEMPORARY_USER = 'temp_user'
export const DEACTIVATED_STATUS = 'deactivated'
export const ACTIVATED_STATUS = 'activated'
export const CREATED_LOG_HOURS_ACTION = {
	add: 'add',
	edit: 'edit',
	edit_accept: 'edit_accpet'
}
export const DATE_REQUEST_FORMAT = 'YYYY-MM-DD'
export const FILTER_TYPE_LOMAT = 'lomat'
export const HOLIDAY_TYPES = {
	isPekkasVapaa: 'isPekkasVapaa',
	isNormalLeave: 'isNormalLeave',
	isSickLeave: 'isSickLeave'
}
export const LEAVE_TYPE_TEXT_FINNISH = {
	hour: 'Työtunti',
	paidLeave: 'Lomapäivä',
	unpaidLeave: 'Pekkasvapaa',
	sickLeave: 'Sairasloma'
}
export const SELECTED_TYPES = {
	lomat: 'lomat'
}
// Time per logged hour limit in minutes(1 day)
export const LOGGED_HOUR_LIMIT = 24 * 60
export const START_TIME_OF_DAY = '08:00'
export const END_TIME_OF_DAY = '16:00'

// order by
export const ORDERBY_PARAMS = {
	startTime: 'startTime',
	endTime: 'endTime',
	asc: 'ASC',
	desc: 'DESC'
}

// iconsize
export const ICON_SIZE_LARGE = 40

// default request

export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD'

// action types
export const ADMIN_LOG_HOURS_ACTIONS = {
	totalHours: 'ADMIN_GET_TOTAL_USERS_LOG_HOURS_SUCCESS',
	allHours: 'ADMIN_GET_ALL_LOG_HOURS_SUCCESS'
}

export const MAX_HOURS = 24

export const LOG_HOURS_STATUS = {
	accepted: {
		value: 'ACCEPTED',
		finnish: 'Hyväksytty!'
	},
	requested: {
		value: 'REQUESTED',
		finnish: 'Odottaa hyväksyntää'
	},
	edited: {
		value: 'USER_EDITED',
		finnish: 'Odottaa hyväksyntää'
	}
}
export const SUCCESS_STATE_CODE = 204
