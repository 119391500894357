/**
 * Attachment picker generic component
 * Allows user to upload an attachment
 * Display file name and (image)
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import React from 'react'
import { KOPPO_IMAGE_URL } from 'constants'

function AttachmentPicker({
	disabled = false,
	handlePickAttachment,
	handleRemoveAttachment,
	icon,
	withImage,
	imageKey,
	label,
	name,
	id = 'attachment-picker',
	content,
	className,
	title,
	item,
	fileName,
	upload,
	deletable,
	onFileDelete
}) {
	/**
	 * Pick file handler
	 *
	 */
	const handlePickFile = e => {
		e.stopPropagation()
		if (
			!e.target.files ||
			!handlePickAttachment ||
			typeof handlePickAttachment !== 'function' ||
			e.defaultPrevented
		) {
			return
		}
		const attachment = e.target.files[0]
		const reader = new FileReader()
		reader.onloadend = () => {
			handlePickAttachment(attachment, reader.result, name)
		}
		reader.readAsDataURL(attachment)
	}

	const renderImage = () => {
		if (upload) {
			return (
				<div className='attachment-image'>
					{<i className='material-icons add'>add</i>}
				</div>
			)
		}
		return <i className='material-icons'>{icon ? icon : 'attach_file'}</i>
	}

	const pickerClassName = ['attachment-picker-container']
	if (disabled) {
		pickerClassName.push('disabled')
	}
	return (
		<div className={pickerClassName.join(' ')}>
			<div
				className={`attachment-picker ${className ? className : ''}`}
				onClick={e => e.stopPropagation()}
			>
				<div className='attachment-picker__upload-button'>
					<label className={'file-upload__label'}>
						<input
							id={name}
							label={label}
							type='file'
							accept={withImage ? '.png,.jpg,.jpeg' : undefined}
							onChange={e =>
								item
									? handlePickFile(e, item.normalizedName)
									: handlePickFile(e)
							}
							className='attachment-picker'
							disabled={disabled}
							name={name}
						/>
						<span className={'file-upload__label-text'}>
							{title ? (
								<div className='title'>{title}</div>
							) : content ? (
								<div>
									<i className='material-icons content'>image</i>
									<i className='material-icons add'>add_circle_outline</i>
								</div>
							) : (
								<div className='icon'>{renderImage()}</div>
							)}
						</span>
						{label ? (
							<span className='attachment-picker__label'>{label}</span>
						) : null}
					</label>
					{fileName ? <div className='fileName'>{fileName}</div> : null}
				</div>
			</div>
		</div>
	)
}

export default AttachmentPicker
