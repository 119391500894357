import React from 'react'
import { KOPPO_IMAGE_URL } from 'constants'

const Avatar = ({
	id,
	title,
	imageKey,
	deletable,
	onImageDelete,
	isEditable
}) => {
	const renderContent = () => {
		if (id && imageKey) {
			return (
				<>
					<img
						src={`${KOPPO_IMAGE_URL}/${imageKey}`}
						alt='image'
						className='avatar__content'
					/>
					{deletable && (
						<i className='material-icons' onClick={() => onImageDelete(id)}>
							clear
						</i>
					)}
				</>
			)
		}
		if (title) {
			return <span className='avatar__content'>{title}</span>
		}
		return <span className='material-icons person'>person</span>
	}
	return (
		<div className='avatar'>
			{renderContent()}
		</div>
	)
}
export default Avatar
