import moment from 'moment'
import {
    DATE_FORMAT, UNPAID_LEAVE_ENTRY,
    PAID_LEAVE_ENTRY,
    SICK_LEAVE_ENTRY,
    UNPAID_LEAVE_ENTRY_FINNISH,
    PAID_LEAVE_ENTRY_FINNISH,
    SICK_LEAVE_ENTRY_FINNISH
} from 'constants'


export const getTotalTime = time => {
    if (!time) {
        return '0 t'
    }
    let hours = Math.floor(time)
    let minutes = Math.round((time - hours) * 60)
    if (minutes === 60) {
        hours = hours + 1
        minutes = 0
    }
    let normalizeMinutes = 0
    if (minutes > 0 && minutes <= 15) {
        normalizeMinutes = 15
    }
    if (minutes > 15 && minutes <= 30) {
        normalizeMinutes = 30
    }
    if (minutes > 30 && minutes <= 45) {
        normalizeMinutes = 45
    }
    if (minutes > 45 && minutes <= 59) {
        normalizeMinutes = 0
        hours = hours + 1
    }
    return `${hours} t ${normalizeMinutes}m`
}
export const getWeekNumber = (date = new Date()) => {
    return moment(date).isoWeek()
}
export const getCurrentPaymentPeriod = () => {
    const currentWeekNum = getWeekNumber()
    const isEven = currentWeekNum % 2 === 0
    let startDate = moment().format()
    let endDate = moment().format()
    if (isEven) {
        startDate = moment().week(currentWeekNum).startOf('week').format('YYYY-MM-DD')
        endDate = moment().week(currentWeekNum + 1).endOf('week').format('YYYY-MM-DD')
    } else {
        startDate = moment().week(currentWeekNum - 1).startOf('week').format('YYYY-MM-DD')
        endDate = moment().week(currentWeekNum).endOf('week').format('YYYY-MM-DD')

    }
    return {
        startDate,
        endDate
    }
}
export const getDuration = (start, end, inString) => {
    const diff = moment(end).diff(moment(start), 'minutes')

    const duration = moment.duration(moment(end).diff(moment(start)))
    // duration in hours
    let hours = parseInt(duration.asHours())

    // duration in minutes
    const minutes = parseInt(duration.asMinutes()) % 60
    let normalizeMinutes = 0
    if (minutes > 0 && minutes <= 15) {
        normalizeMinutes = 15
    }
    if (minutes > 15 && minutes <= 30) {
        normalizeMinutes = 30
    }
    if (minutes > 30 && minutes <= 45) {
        normalizeMinutes = 45
    }
    if (minutes > 45 && minutes <= 59) {
        normalizeMinutes = 0
        hours = hours + 1
    }
    if (inString) {
        return `${Math.ceil(hours)}:${Math.ceil(normalizeMinutes)}`
    }
    return `${Math.ceil(hours).toFixed(0)}t ${Math.ceil(normalizeMinutes)}min`
}
export const getTimeDuration = (startDate = moment(), endDate = moment(), unit) => {
    return moment(endDate).diff(moment(startDate), unit)
}

export const getSalaryPeriodOptions = salaryPeriods => {
    if (!salaryPeriods || salaryPeriods.length === 0) {
        return []
    }
    return salaryPeriods.map((range, key) => {
        const string = `${moment(range.startDate).format(
            DATE_FORMAT
        )} - ${moment(range.endDate).format(DATE_FORMAT)}`
        const value = {
            startDate: moment(range.startDate).format('YYYY-MM-DD'),
            endDate: moment(range.endDate).format('YYYY-MM-DD'),
            label: string,
            key: key
        }
        return {
            value,
            text: string
        }
    })

}

export const getLeaveType = (type) => {
    switch (type) {
        case UNPAID_LEAVE_ENTRY:
            return UNPAID_LEAVE_ENTRY_FINNISH
        case PAID_LEAVE_ENTRY:
            return PAID_LEAVE_ENTRY_FINNISH
        case SICK_LEAVE_ENTRY:
            return SICK_LEAVE_ENTRY_FINNISH
        default:
            return ''
    }
}