import {
    isEmail,
    isIBAN,
    isMobilePhone,
    isPostalCode,
    isString
} from 'class-validator'
import { isValid } from "finnish-personal-identity-code-validator";

export const getFields = (employee, user, isAdmin) => {
    if (!employee) {
        return null
    }
    const {
        firstName,
        lastName,
        email,
        streetAddress,
        postalCode,
        city,
        phoneNumber,
        bankAccountNumber,
        socialSecurityNumber,
        avatar,
        role
    } = employee
    const adminFields = [
        {
            name: 'phoneNumber',
            value: phoneNumber,
            label: 'Puhelin',
            type: 'text'
        },
        {
            name: 'email',
            value: email,
            label: 'Sähköposti',
            type: 'email'
        },
        {
            name: 'bankAccountNumber',
            value: bankAccountNumber,
            label: 'Tilinumero',
            type: 'text'
        },
        {
            name: 'role',
            value: role,
            label: 'Väliaikainen/keikkatyöntekijä',
            type: 'checkbox'
        }

    ]
    const normalUserFields = [
        {
            name: 'streetAddress',
            value: streetAddress,
            label: 'Osoite',
            type: 'text'

        },
        {
            name: 'postalCode',
            value: postalCode,
            label: 'Postinumero',
            type: 'text'

        },
        {
            name: 'city',
            value: city,
            type: 'text',
            label: 'Kaupunki'

        },
        {
            name: 'phoneNumber',
            value: phoneNumber,
            type: 'text',
            label: 'Puhelin'
        },
        {
            name: 'email',
            value: email,
            type: 'text',
            label: 'Sähköposti'
        },
        {
            name: 'bankAccountNumber',
            value: bankAccountNumber,
            label: 'Tilinumero',
            type: 'text'
        }
    ]
    const AdminAvatarFields = {
        avatar: {
            image: avatar ? avatar : null,
            isEditable: false
        },
        name: `${firstName} ${lastName}`,
        address: streetAddress,
        postalCode,
        city,
        socialSecurityNumber

    }
    const avatarFields = {
        avatar: {
            image: avatar ? avatar : null,
            isEditable: true
        },
        name: `${firstName} ${lastName}`
    }
    if (user.id === employee.id && isAdmin) {
        return {
            normalFields: avatarFields,
            editableFields: normalUserFields
        }
    }
    return isAdmin ? {
        normalFields: AdminAvatarFields,
        editableFields: adminFields
    } : {
            normalFields: avatarFields,
            editableFields: normalUserFields
        }
}


export const getErrorArr = (editableFields) => {

    let form = {}
    editableFields.forEach(field => {
        form = {
            ...form,
            [field.name]: field.value
        }
    })
    const {
        email,
        streetAddress,
        postalCode,
        city,
        phoneNumber,
        bankAccountNumber
    } = form
    return [
        {
            name: 'email',
            value: email,
            error: checkValidity('email', email) ? null : 'Virheellinen sähköpostiosoite'
        },
        {
            name: 'streetAddress',
            value: streetAddress,
            error: checkValidity('streetAddress', streetAddress) ? null : 'Virheellinen katuosoite'
        },
        {
            name: 'postalCode',
            value: postalCode,
            error: checkValidity('postalCode', postalCode) ? null : 'Virheellinen postinumero'
        },
        {
            name: 'city',
            value: city,
            error: checkValidity('city', city) ? null : 'Virheellinen kaupunki'
        },
        {
            name: 'phoneNumber',
            value: phoneNumber,
            error: checkValidity('phoneNumber', phoneNumber) ? null : 'Väärä puhelinnumero'
        },
        {
            name: 'bankAccountNumber',
            value: bankAccountNumber,
            error: checkValidity('bankAccountNumber', bankAccountNumber) ? null : 'Virheellinen pankkitilin numero'
        }
    ]

}

const checkValidity = (name, value) => {
    if (!value) {
        return false
    }
    switch (name) {
        case 'firstName':
        case 'lastName':
        case 'streetAddress':
        case 'city':
            return isString(value)
        case 'socialSecurityNumber':
            return isValid(value)
        case 'email':
            return isEmail(value)
        case 'postalCode':
            return isPostalCode(value, 'FI')
        case 'phoneNumber':
            return isMobilePhone(value)
        case 'bankAccountNumber':
            return isIBAN(value)
        default: return true

    }

}

