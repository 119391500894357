/**
 * Admin user action
 *
 * @author Sravanthi Bandaru<sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { adminUserTypes as types } from 'actions/types'

const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

const getAllEmployees = () => ({
	type: types.getAllEmployees,
	payload: {
		request: {
			method: 'GET',
			url: `/admin/users`
		}
	}
})
const changeUserStatus = (id, status) => ({
	type: types.changeUserStatus,
	payload: {
		request: {
			method: 'PATCH',
			url: `/admin/users/${id}/status`,
			data: {
				status
			}
		}
	}
})

const changeFormField = (name, value) => {
	return {
		type: types.changeFormField,
		name,
		value
	}
}
const addUser = (tempUser, data) => ({
	type: types.addUser,
	payload: {
		request: {
			method: 'POST',
			url: '/admin/users',
			params: {
				tempUser
			},
			data
		}
	}
})
const getSelectedEmployee = (id) => ({
	type: types.getSelectedEmployee,
	payload: {
		request: {
			method: 'GET',
			url: `/admin/users/${id}`
		}
	}
})
const editEmployee = (id, data) => ({
	type: types.editEmployee,
	payload: {
		request: {
			method: 'PATCH',
			url: `/admin/users/${id}`,
			data
		}
	}
})
const resetFields = () => {
	return {
		type: types.resetFields
	}
}

const resetError = () => {
	return {
		type: types.resetError
	}
}

export const adminUserActions = {
	changeField,
	resetError,
	resetFields,
	getAllEmployees,
	addUser,
	changeFormField,
	changeUserStatus,
	getSelectedEmployee,
	editEmployee
}
