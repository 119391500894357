/**
 * Reducer helpers
 *
 * @author Sravanthi <sravanthi@vertics.co>
 *
 */
/**
 * Format and humanize error message
 * to show specific error message in UI
 *
 * @param {Object} error object from server
 * @return {Object} status code + title + detail
 */
import moment from 'moment'

import { START_TIME_OF_DAY, END_TIME_OF_DAY, DATE_FORMAT, REQUEST_DATE_FORMAT } from 'constants'
function normalizeError(error) {
	return {
		status: null,
		msg: error.response ? getTranslatedError(error.response.data) : 'Oho! Jotain meni pieleen',
		detail: 'Oho! Jotain meni pieleen'
	}

}
const getTranslatedError = error => {
	if (!error) {
		return 'Oho! Jotain meni pieleen'
	}
	switch (error.message) {
		case 'Email has been used':
			return 'Sähköposti on käytetty'
		case 'Wrong password':
			return 'Väärä salasana'
		case 'Collapsed hour':
			return 'Päällekkäinen kirjaus'
		case 'Logged hour is accepted':
			return 'Kirjattu työaika on jo hyväksytty eikä sitä voi enää poistaa tai muokata'
		case "Can't log/edit hour for the next payment period":
			return 'Työajan kirjaaminen seuraavalle palkkakaudelle ei ole mahdollista'
		default:
			return error.message

	}
}
const renderValue = value => {
	const timeToReturn = new Date(value)
	timeToReturn.setMilliseconds(
		Math.round(timeToReturn.getMilliseconds() / 1000) * 1000
	)
	timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60)
	timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15)
	return timeToReturn
}
const defaultTime = (name) => {
	const timeToReturn = moment().format('L')
	if (name.includes('start')) {
		const time = `${timeToReturn} ${START_TIME_OF_DAY}`
		return `${timeToReturn} ${START_TIME_OF_DAY}`
	} else if (name.includes('end')) {
		return `${timeToReturn} ${END_TIME_OF_DAY}`
	}
}
export const getWeekNumber = (date = new Date()) => {
	return moment(date).isoWeek()
}
const getCurrentPaymentPeriod = () => {
	const currentWeekNum = getWeekNumber()
	const isEven = currentWeekNum % 2 === 0
	let startDate = moment().format()
	let endDate = moment().format()
	if (isEven) {
		startDate = moment().week(currentWeekNum).startOf('week').isoWeekday(1)
		endDate = moment().week(currentWeekNum + 1).endOf('week').isoWeekday(1)
	} else {
		startDate = moment().week(currentWeekNum - 1).startOf('week').isoWeekday(1)
		endDate = moment().week(currentWeekNum).endOf('week').isoWeekday(1)

	}
	return {
		startDate,
		endDate,
		label: `${moment(startDate).format(DATE_FORMAT)
			} - ${moment(endDate).format(DATE_FORMAT)}`
	}
}
const getCurrentSalaryPeriod = data => {
	if (!data || data.length === 0) {
		return {
			startDate: moment().startOf('year').format(REQUEST_DATE_FORMAT),
			endDate: moment().endOf('year').format(REQUEST_DATE_FORMAT)
		}
	}
	return data.find(item => moment().isSameOrAfter(moment(item.startDate)) &&
		moment().isSameOrBefore(moment(item.endDate)))

}

export {
	normalizeError,
	renderValue,
	defaultTime,
	getCurrentPaymentPeriod,
	getCurrentSalaryPeriod
}

