import React, { lazy } from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'

// Code splitting and lazy loading
const CheckInfo = lazy(() =>
	import(/* webpackChunkName: "CheckInfo" */ 'pages/CheckInfo')
)
const MainPage = lazy(() =>
	import(/* webpackChunkName: "MainPage" */ 'pages/MainPage')
)
const AdminMainPage = lazy(() =>
	import(/* webpackChunkName: "AdminMainPage" */ 'pages/AdminMainPage')
)
const UserManagement = lazy(() =>
	import(/* webpackChunkName: "UserManagement" */ 'pages/AdminUserManagement')
)
const AddEmployee = lazy(() =>
	import(/* webpackChunkName: "AddEmployee" */ 'pages/AddUser')
)
import Profile from 'pages/Profile' // temporary fix to avoid bug 

const PendingWorkHours = lazy(() =>
	import(/* webpackChunkName: "PendingWorkHours" */ 'pages/PendingWorkHours')
)
const AdminWorkHours = lazy(() =>
	import(/* webpackChunkName: "AdminWorkHours" */ 'pages/AdminWorkHours')
)
const AddHours = lazy(() =>
	import(/* webpackChunkName: "AddHours" */ 'pages/AddHours')
)
const EmployeeWorkHoursDetails = lazy(() =>
	import(
		/* webpackChunkName: "EmployeeWorkHoursDetails" */ 'shared/components/EmployeeWorkHoursDetails'
	)
)
const HoursForm = lazy(() =>
	import(/* webpackChunkName: "HoursForm" */ 'shared/components/HoursForm')
)

// Constants
import { routerPath, pageTitle } from 'constants'

export default withRouter(props => (
	<Switch>
		{!props.isAdmin && (
			<Route
				path={routerPath.mainPage}
				component={MainPage}
				title={pageTitle.mainPage}
				exact
			/>
		)}
		{!props.isAdmin && (
			<Route
				path={routerPath.addHours}
				component={AddHours}
				title={pageTitle.addHours}
				exact
			/>
		)}
		{!props.isAdmin && (
			<Route
				path={routerPath.checkInfoList}
				component={CheckInfo}
				title={pageTitle.checkInfoList}
				exact
			/>
		)}
		{!props.isAdmin && (
			<Route
				path={routerPath.checkInfoHandle}
				component={CheckInfo}
				title={pageTitle.checkInfoHandle}
				exact
			/>
		)}
		{props.isAdmin && (
			<Route
				path={routerPath.adminCheckWorkHour}
				component={CheckInfo}
				title={pageTitle.adminCheckWorkHour}
				exact
			/>
		)}
		{props.isAdmin && (
			<Route
				path={routerPath.adminCheckWorkHourEdit}
				component={CheckInfo}
				title={pageTitle.adminCheckWorkHourEdit}
				exact
			/>
		)}
		{props.isAdmin && (
			<Route
				path={routerPath.adminAddWorkHour}
				component={HoursForm}
				title={pageTitle.adminAddWorkHour}
				exact
			/>
		)}
		{props.isAdmin && (
			<Route
				path={routerPath.adminMainPage}
				component={AdminMainPage}
				title={pageTitle.AdminMainPage}
				exact
			/>
		)}
		{props.isAdmin && (
			<Route
				path={routerPath.adminWorkhours}
				component={AdminWorkHours}
				title={pageTitle.adminWorkhours}
				exact
			/>
		)}
		{props.isAdmin && (
			<Route
				path={routerPath.adminPendingWorkHour}
				component={PendingWorkHours}
				title={pageTitle.adminPendingWorkHour}
				exact
			/>
		)}
		{props.isAdmin && (
			<Route
				path={routerPath.adminUserManagement}
				component={UserManagement}
				title={pageTitle.adminUserManagement}
				exact
			/>
		)}
		{props.isAdmin && (
			<Route
				path={routerPath.adminAddEmployee}
				component={AddEmployee}
				title={pageTitle.adminAddEmployee}
				exact
			/>
		)}
		{props.isAdmin && (
			<Route
				path={routerPath.employeeWorkHoursDetails}
				component={EmployeeWorkHoursDetails}
				title={pageTitle.employeeWorkHoursDetails}
				exact
			/>
		)}
		<Route
			path={routerPath.ownProfile}
			component={Profile}
			title={pageTitle.profile}
			exact
		/>
		{props.isAdmin && (
			<Route
				path={routerPath.userProfile}
				component={Profile}
				title={pageTitle.userProfile}
				exact
			/>
		)}
		<Redirect
			to={props.isAdmin ? routerPath.adminMainPage : routerPath.mainPage}
		/>
	</Switch>
))
