/**
 * Root client file
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */

// WORK INSTRUCTIONS:
// - Please do not remove the above header. It protects the IPR intrests of our company.
// - Please replace the author name with your own full real name.

// React stuffs
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'

import './services/i18n'
import { store, persistor } from './store'

// history from reducer
import { history } from './reducers'

// Styling
import './styles/index.scss'

// Components
import App from './App'

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('app')
)
