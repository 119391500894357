/**
 * Password reducer
 *
 * @author Sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { passwordTypes as types } from '../actions/types'
import { normalizeError } from './utils'

const initialState = {
	password: '',
	rePassword: '',
	resetKey: null,
	email: null,
	error: null,
	loading: false
}

export const passwordReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeField:
			return {
				...state,
				[action.name]: action.value,
				error: initialState.error
			}
		case types.resetPassword:
			return {
				...state,
				loading: true
			}

		case types.resetPasswordSuccess:
			return {
				...state,
				loading: false
			}
		case types.resetPasswordFail:
			return {
				...state,
				error: action.error,
				loading: false
			}
		case types.setPassword:
			return {
				...state,
				loading: true
			}

		case types.setPasswordSuccess:
			return {
				...state,
				loading: false
			}
		case types.setPasswordFail:
			return {
				...state,
				error: normalizeError(action.error),
				loading: false
			}
		case types.resetFields:
			return {
				...initialState
			}
		case types.resetError:
			return {
				...state,
				error: null
			}
		default:
			return state
	}
}

export const passwordSelectors = {
	getPassword: (state = initialState) => state.password,
	getRePassword: (state = initialState) => state.rePassword,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error,
	getEmail: (state = initialState) => state.email
}
