/**
 * check info reducer
 *
 * @author Sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { checkInfoTypes as types } from '../actions/types'
import { normalizeError, getCurrentPaymentPeriod } from './utils'
const initialState = {
    loading: false,
    error: null,
    dateRange: [new Date(), new Date()],
    loggedHours: [],
    selectedShift: null,
    page: null,
    isListByDate: false,
    selectedSalaryRange: getCurrentPaymentPeriod()
}

export const checkInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.changeField:
            return {
                ...state,
                [action.name]: action.value,
                error: initialState.error
            }
        //
        case types.getLoggedHours:
            return {
                ...state,
                loading: true
            }

        case types.getLoggedHoursSuccess:
            return {
                ...state,
                loggedHours: action.payload.data,
                loading: false
            }

        case types.getLoggedHoursFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        case types.resetFields:
            return {
                ...initialState
            }
        case types.resetCredentials:
            return {
                ...state,
                email: null,
                password: null,
                error: null,
                loading: false
            }
        case types.resetError:
            return {
                ...state,
                error: null
            }
        
        default:
            return state
    }
}

export const checkInfoSelectors = {
    getDateRange: (state = initialState) => state.dateRange,
    getLoggedHours: (state = initialState) => state.loggedHours,
    getSelectedShift: (state = initialState) => state.selectedShift,
    getPage: (state = initialState) => state.page,
    getError: (state = initialState) => state.error,
    getIsListByDate: (state = initialState) => state.isListByDate,
    getSelectedSalaryRange: (state = initialState) => state.selectedSalaryRange
}
