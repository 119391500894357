/**
 * Created by Hai Anh on 5/4/20
 */

import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import Modal from '../components/Modal'

const withLoadingScreen = WrapperComponent => {
	class LoadingScreen extends React.PureComponent {
		constructor() {
			super()
			this.state = {
				loading: false
			}
			this.mounted = false
			this.closeLoadingTimeout = null
		}

		componentDidMount() {
			this.mounted = true
		}

		componentWillUnmount() {
			this.mounted = false
			if (this.closeLoadingTimeout) {
				clearTimeout(this.closeLoadingTimeout)
			}
		}

		showLoading = (ignoreTimeout = false) => {
			this.setState(
				{
					loading: true
				},
				() => {
					if (ignoreTimeout) {
						return
					}
					this.closeLoadingTimeout = setTimeout(() => {
						if (this.mounted) {
							this.setState({
								loading: false
							})
						}
					}, 1000000)
				}
			)
		}

		hideLoading = (callback = null) => {
			if (callback !== null) {
				this.setState({
					loading: false
				})
			} else {
				this.setState(
					{
						loading: false
					},
					callback
				)
			}
		}

		renderLoading = () => {
			return (
				<>
					<Loader
						type='Puff'
						color='#00BFFF'
						height={50}
						width={500}
						type='Oval'
						className='loader'
					/>
					<span className="text">Sivua ladataan..</span>
				</>
			)
		}

		render() {
			const newProps = {
				showLoading: this.showLoading,
				hideLoading: this.hideLoading
			}
			return (
				<div className='loading-hoc__container'>
					<Modal
						show={this.state.loading}
						className={'loading-modal__container'}
						showClose={false}
					>
						{this.renderLoading()}
					</Modal>
					<WrapperComponent {...this.props} {...newProps} />
				</div>
			)
		}
	}

	hoistNonReactStatics(LoadingScreen, WrapperComponent)
	return LoadingScreen
}

export default withLoadingScreen
