/**
 * Authentication hooks
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import React from 'react'
import {usePrevious} from './utils'

export const AuthorizationContext = React.createContext({
	user: null,
	loading: false
})

export const authorizedState = {
	idle: 'idle',
	pending: 'pending',
	accepted: 'accepted',
	rejected: 'rejected'
}

const useAuthorizationContext = () => {
	const context = React.useContext(AuthorizationContext)
	if (!context) {
		throw new Error('Invalid authorization context. This is due to either lack of Provider or bad context value')
	}

	return context
}

/**
 * Use authorization hook
 * Keep track of user data and loading state to identify if user is:
 * - Authenticated if userId exists in local storage
 * - Authorized if we could actually get user data from server
 *
 * @return {Object} authenticated and authorized state
 */
export const useAuthorization = () => {
	const [isAuthorized, setAuthorized] = React.useState(authorizedState.idle)
	const isAuthenticated = !!localStorage.getItem('userId')

	const { user, loading } = useAuthorizationContext()
	const previousAuthorizedState = usePrevious(isAuthorized)

	React.useEffect(() => {
		if (loading) {
			setAuthorized(authorizedState.pending)
		} else {
			if (previousAuthorizedState === authorizedState.pending && !user) {
				setAuthorized(authorizedState.rejected)
				return
			}

			setAuthorized(authorizedState.idle)
		}
	}, [loading])

	React.useEffect(() => {
		if (!user) {
return
}
		// We could do more checking here with user data to define if the user is actually authorized
		setAuthorized(authorizedState.accepted)
	}, [user])

	return {
		isAuthorized,
		isAuthenticated
	}
}


