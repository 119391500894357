import React from 'react'
import { Button, Menu, MenuItem } from '@material-ui/core'

const Dropdown = ({ title, icon, options, onMenuItemClick, imgId }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)

	const _onButtonClick = event => {
		setAnchorEl(event.currentTarget)
	}
	const _onMenuItemClick = (e, item) => {
		handleClose()
		if (onMenuItemClick && typeof onMenuItemClick === 'function') {
			onMenuItemClick(e, item)
		}
	}

	const handleClose = () => {
		setAnchorEl(null)
	}
	const renderItems = () => {
		if (!options || options.length === 0) {
			return <MenuItem onClick={handleClose}>no options</MenuItem>
		}
		return options.map((item, key) => (
			<MenuItem onClick={(e) => _onMenuItemClick(e, item.value)} key={key}>
				{item.text}
			</MenuItem>
		))
	}
	const renderButtonContent = () => {
		if (title) {
			return <span className='title'>{title}</span>
		}

		return <span>Menu</span>
	}
	return (
		<div>
			<Button
				aria-controls='simple-menu'
				aria-haspopup='true'
				onClick={_onButtonClick}
			>
				{renderButtonContent()}
			</Button>
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{renderItems()}
			</Menu>
		</div>
	)
}
export default Dropdown
