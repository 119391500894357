import React, { lazy } from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'

// Code splitting and lazy loading
const Login = lazy(() => import(/* webpackChunkName: "Login" */ 'pages/Login'))
const RecoverPassword = lazy(() =>
	import(/* webpackChunkName: "RecoverPassword" */ 'pages/RecoverPassword')
)
const ForgotPassword = lazy(() =>
	import(/* webpackChunkName: "ForgotPassword" */ 'pages/ForgotPassword')
)

// Constants
import { routerPath, pageTitle } from 'constants'

export default withRouter(props => (
	<Switch>
		<Route
			path={routerPath.forgotPassword}
			component={ForgotPassword}
			title={pageTitle.forgotPassword}
			exact
		/>
		<Route
			path={routerPath.setPassword}
			component={RecoverPassword}
			title={pageTitle.setPassword}
			exact
		/>
		<Route
			path={routerPath.passwordFail}
			component={RecoverPassword}
			title={pageTitle.passwordFail}
			fail
			exact
		/>
		<Route
			path={routerPath.login}
			component={Login}
			exact
			title={pageTitle.login}
		/>
		<Redirect to={routerPath.login} />
	</Switch>
))
