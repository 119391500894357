/**
 * User action creators
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { userTypes as types } from './types'

const getUser = () => {
	return dispatch => {
		dispatch({
			type: types.getUser
		})

		setTimeout(() => {
			return dispatch({
				type: types.getUserSuccess,
				payload: {
					data: { name: 'Hello world', id: Math.round(Math.random() * 100000) }
				}
			})
		}, 500)
	}
}
const logout = () => {
	const refreshToken = localStorage.getItem('refreshToken')
	return {
		type: types.logout,
		payload: {
			request: {
				method: 'GET',
				url: '/auth/signout',
				params: {
					refreshToken
				}
			}
		}
	}
}
const resetFields = () => {
	return {
		type: types.resetFields
	}
}
const changeField = (name, value, fieldType) => {
    return {
        type: types.changeField,
        name,
        value,
        fieldType
    }
}
const updateInfo = (id, data) => ({
	type: types.updateInfo,
	payload: {
		request: {
			method: 'PATCH',
			url: `/users/${id}`,
			data
		}
	}
})
const changePassword = ( data) => ({
	type: types.changePassword,
	payload: {
		request: {
			method: 'PATCH',
			url: `/users/my/password`,
			data
		}
	}
})
const getNewJWT = refreshToken => {
    return {
        type: types.getNewJWT,
        payload: {
            request: {
                method: 'GET',
                url: `/auth/token/refresh`,
                params: {
                    refreshToken
                }
            }
        }
    }
}

export const userActions = {
	getUser,
	resetFields,
	changeField,
	changePassword,
	logout,
	updateInfo,
	getNewJWT
}