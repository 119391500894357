/**
 * Check info action
 *
 * @author Sravanthi Bandaru<sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { checkInfoTypes as types } from './types'
import { store } from '../store'

const changeField = (name, value) => {
    return {
        type: types.changeField,
        name,
        value
    }
}
const getLoggedHours = (userId, startDate = store.getState().logHours.startDate, endDate = store.getState().logHours.endDate) => ({
    type: types.getLoggedHours,
    payload: {
        request: {
            method: 'GET',
            url: `/logged-hours/users/${userId}`,
            params: {
                startDate,
                endDate
            }
        }
    }
})

const resetFields = () => {
    return {
        type: types.resetFields
    }
}

const resetError = () => {
    return {
        type: types.resetError
    }
}
export const checkInfoActions = {
    changeField,
    resetError,
    resetFields,
    getLoggedHours
}
