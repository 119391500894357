/**
 * log hours reducer
 *
 * @author Sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { logHoursTypes as types } from '../actions/types'
import { normalizeError, defaultTime, getCurrentSalaryPeriod } from './utils'
import moment from 'moment'
import { CREATED_LOG_HOURS_ACTION } from 'constants'
const initialState = {
    start: defaultTime('start'),
    end: defaultTime('end'),
    isStartValid: true,
    isEndValid: true,
    dailyAllowance: 'no',
    moreInfo: '',
    error: null,
    loading: false,
    isPekkasVapaa: false,
    isNormalLeave: false,
    isSickLeave: false,
    action: CREATED_LOG_HOURS_ACTION.add,
    salaryPeriods: [],
    currentSalaryPeriod: {
        startDate: moment(),
        endDate: moment()
    },
    activeDate: {
        start: moment()
            .startOf('month')
            .startOf('week')
            .format(),
        end: moment()
            .endOf('month')
            .endOf('week')
            .format()
    }
}

export const logHoursReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.changeField:
            return {
                ...state,
                [action.name]: action.value
            }
        case types.createLoggedHours:
            return {
                ...state,
                loading: true
            }

        case types.createLoggedHoursSuccess:
            return {
                ...state,
                loading: false
            }

        case types.createLoggedHoursFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        case types.deleteLogHour:
            return {
                ...state,
                loading: true
            }

        case types.deleteLogHourSuccess:
            return {
                ...state,
                loading: false
            }

        case types.deleteLogHourFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        case types.updateLoggedHours:
            return {
                ...state,
                loading: true
            }

        case types.updateLoggedHoursSuccess:
            return {
                ...state,
                loading: false
            }

        case types.updateLoggedHoursFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        case types.getAvailableSalaryPeriods:
            return {
                ...state,
                loading: true
            }

        case types.getAvailableSalaryPeriodsSuccess:
            const currentSalaryPeriod = getCurrentSalaryPeriod(action.payload.data)
            return {
                ...state,
                salaryPeriods: action.payload.data,
                currentSalaryPeriod,
                loading: false
            }

        case types.getAvailableSalaryPeriodsFail:
            return {
                ...state,
                loading: false,
                error: normalizeError(action.error)
            }
        case types.resetFields:
            return {
                ...initialState
            }
        case types.resetError:
            return {
                ...state,
                error: null
            }
        case types.resetForm:
            return {
                ...state,
                start: defaultTime('start'),
                end: defaultTime('end'),
                isPekkasVapaa: false,
                isNormalLeave: false,
                isSickLeave: false,
                dailyAllowance: 'no',
                moreInfo: '',
                error: null
            }
        default:
            return state
    }
}

export const logHoursSelectors = {
    getLoading: (state = initialState) => state.loading,
    getError: (state = initialState) => state.error,
    getStart: (state = initialState) => state.start,
    getEnd: (state = initialState) => state.end,
    getStartValidity: (state = initialState) => state.isStartValid,
    getEndValidity: (state = initialState) => state.isEndValid,
    getDailyAllowance: (state = initialState) => state.dailyAllowance,
    getMoreInfo: (state = initialState) => state.moreInfo,
    getIsPekkasVapaa: (state = initialState) => state.isPekkasVapaa,
    getIsNormalLeave: (state = initialState) => state.isNormalLeave,
    getIsSickLeave: (state = initialState) => state.isSickLeave,
    getAction: (state = initialState) => state.action,
    getSalaryPeriods: (state = initialState) => state.salaryPeriods,
    getCurrentSalaryPeriod: (state = initialState) => state.currentSalaryPeriod,
    getActiveDate: (state = initialState) => state.activeDate
}
