import { fileTypes as types } from './types'

const fileUpload = (data) => ({
    type: types.fileUpload,
    payload: {
        request: {
            method: 'POST',
            url: '/files',
            data
        }
    }
})

export const fileActions = {
    fileUpload
}