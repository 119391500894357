/**
 * Admin User reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { adminUserTypes as types } from '../actions/types'
import { normalizeError } from './utils'
import { USER_ROLE_TEMPORARY_USER } from 'constants'
const initialState = {
	loading: false,
	error: null,
	allEmployees: [],
	selectedEmployee: null,
	form: {
		firstName: '',
		lastName: '',
		socialSecurityNumber: '',
		bankAccountNumber: '',
		streetAddress: '',
		phoneNumber: '',
		email: '',
		postalCode: '',
		city: '',
		tempUser: false
	},
	isTempUser: false
}

export const adminUserReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeField:
			return {
				...state,
				[action.name]: action.value,
				error: initialState.error
			}
		case types.changeFormField:
			return {
				...state,
				form: {
					...state.form,
					[action.name]: action.value
				}
			}
		case types.getAllEmployees:
			return {
				...state,
				loading: true
			}
		case types.getAllEmployeesSuccess:
			return {
				...state,
				allEmployees: action.payload.data,
				loading: false
			}
		case types.getAllEmployeesFail:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case types.editEmployee:
			return {
				...state,
				loading: true
			}
		case types.editEmployeeSuccess:
			return {
				...state,
				loading: false
			}
		case types.editEmployeeFail:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case types.getSelectedEmployee:
			return {
				...state,
				loading: true
			}
		case types.getSelectedEmployeeSuccess:
			const { role } = action.payload.data

			return {
				...state,
				selectedEmployee: action.payload.data,
				isTempUser: role === USER_ROLE_TEMPORARY_USER,
				loading: false
			}
		case types.getSelectedEmployeeFail:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case types.changeUserStatus:
		case types.addUser:
			return {
				...state,
				loading: true
			}
		case types.addUserSuccess:
		case types.changeUserStatusSuccess:
			return {
				...state,
				loading: false
			}
		case types.changeUserStatusFail:
		case types.addUserFail:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case types.resetFields:
			return {
				...initialState
			}
		case types.resetError:
			return {
				...state,
				error: null
			}
		default:
			return state
	}
}

export const adminUserSelectors = {
	getAllEmployees: (state = initialState) => state.allEmployees,
	getSelectedEmployee: (state = initialState) => state.selectedEmployee,
	getFormFields: (state = initialState) => state.form,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error,
	getIsTempUser: (state = initialState) => state.isTempUser
}
